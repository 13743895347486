<template>
  <div class="l-wrapper loggedin">
    <AppHeader pageTitle="委託契約書登録" />
    <AppSideMenu />
    <main class="l-main">
      <div class="l-container">
        <ErrorMessage :errMsgs="errMsgs" />
        <div class="c-form__reqTxt l-item">：入力必須</div>

        <div class="l-contents l-box">
          <div class="l-box title-wrap">
            <h3 class="c-lead icon">基本情報</h3>
          </div>
          <div>
            <div class="c-inputWrap">
              <div class="c-inputWrap__label required">
                <label>契約開始日</label>
              </div>
              <div class="c-inputWrap__items">
                <InputDate
                  :value.sync="form.activeStartDate"
                  placeholder="選択してください"
                  :validation="validateJustBlank"
                  :isValid.sync="validate.activeStartDate.isValid"
                  :errMsg.sync="validate.activeStartDate.errMsg"
                />
              </div>
            </div>
            <div class="c-inputWrap">
              <div class="c-inputWrap__label">
                <label>契約終了日</label>
              </div>
              <div class="c-inputWrap__items">
                <InputDate
                  :value.sync="form.activeEndDate"
                  placeholder="選択してください"
                  :validation="validateJustBlank"
                  :isValid.sync="validate.activeEndDate.isValid"
                  :errMsg.sync="validate.activeEndDate.errMsg"
                />
              </div>
            </div>

            <div class="c-inputWrap">
              <div class="c-inputWrap__label">
                <label>更新中</label>
              </div>
                <div class="c-inputWrap__items">
                  <div class="c-checkbox">
                    <input
                      id="isHq-cb"
                      type="checkbox"
                      v-model="form.isUpdate"
                    />
                    <label class="c-checkbox__label" for="isHq-cb">
                      <span class="c-checkbox__box"></span>
                      更新中
                    </label>
                  </div>
                </div>
            </div>
          </div>
        </div>

        <!-- 担当者情報 -->
        <div class="l-contents l-box">
          <div class="l-box title-wrap">
            <h3 class="c-lead icon">担当者情報</h3>
          </div>
          <div>
            <div class="c-inputWrap">
              <div class="c-inputWrap__label">
                <label>登録担当者</label>
              </div>
              <div class="c-inputWrap__items">
                <InputText
                    :value.sync="form.registMemberName"
                    placeholder="入力してください"
                    tooltip="12文字以内で入力してください"
                    :validation="validateMaxLen"
                    :validateArg="12"
                    :isValid.sync="validate.registMemberName.isValid"
                    :errMsg.sync="validate.registMemberName.errMsg"
                />
              </div>
            </div>
            <div class="c-inputWrap">
              <div class="c-inputWrap__label">
                <label>引き渡し担当者</label>
              </div>
              <div class="c-inputWrap__items">
                <InputText
                    :value.sync="form.generateMemberName"
                    placeholder="入力してください"
                    tooltip="12文字以内で入力してください"
                    :validation="validateMaxLen"
                    :validateArg="12"
                    :isValid.sync="validate.generateMemberName.isValid"
                    :errMsg.sync="validate.generateMemberName.errMsg"
                />
              </div>
            </div>
          </div>
        </div>

        <!-- 受渡確認票 / 一括登録通知配布先 -->
        <div class="l-contents l-box">
          <div class="l-box title-wrap">
            <h3 class="c-lead icon">受渡確認票 / 一括登録通知配布先</h3>
          </div>
          <div>
            <div class="c-inputWrap">
              <div class="c-inputWrap__label">
                <label>送信フラグ</label>
              </div>
              <div class="c-inputWrap__items">
                <div class="c-checkbox">
                  <input
                      id="is-delivery-confirm-send-flag"
                      type="checkbox"
                      v-model="form.entrustDeliveryConfirmation.isDeliveryConfirmSendFlag"
                  />
                  <label class="c-checkbox__label" for="is-delivery-confirm-send-flag">
                    <span class="c-checkbox__box"></span>
                    送信する
                  </label>
                </div>
              </div>
            </div>
            <div class="c-inputWrap">
              <div class="c-inputWrap__label">
                <label>送付先email（排出）</label>
              </div>
              <div class="c-inputWrap__items">
                <InputText
                    :value.sync="form.entrustDeliveryConfirmation.generateDeliveryConfirmMail"
                    tooltip="メールアドレスを入力してください"
                    :validation="validateMail"
                    :isValid.sync="validate.generateDeliveryConfirmMail.isValid"
                    :errMsg.sync="validate.generateDeliveryConfirmMail.errMsg"
                />
              </div>
            </div>
            <div class="c-inputWrap">
              <div class="c-inputWrap__label">
                <label>送付先email（運搬）</label>
              </div>
              <div class="c-inputWrap__items">
                <InputText
                    :value.sync="form.entrustDeliveryConfirmation.collectDeliveryConfirmMail"
                    placeholder="入力してください"
                    tooltip="メールアドレスを入力してください"
                    :validation="validateMail"
                    :isValid.sync="validate.collectDeliveryConfirmMail.isValid"
                    :errMsg.sync="validate.collectDeliveryConfirmMail.errMsg"
                />
              </div>
            </div>
            <div class="c-inputWrap">
              <div class="c-inputWrap__label">
                <label>送付先email（処分）</label>
              </div>
              <div class="c-inputWrap__items">
                <InputText
                    :value.sync="form.entrustDeliveryConfirmation.disposalDeliveryConfirmMail"
                    placeholder="入力してください"
                    tooltip="メールアドレスを入力してください"
                    :validation="validateMail"
                    :validateArg="256"
                    :isValid.sync="validate.disposalDeliveryConfirmMail.isValid"
                    :errMsg.sync="validate.disposalDeliveryConfirmMail.errMsg"
                />
              </div>
            </div>

            <template v-for="(anyMail, anyMailIndex) in form.entrustDeliveryConfirmation.anyMailList">
              <div class="c-inputWrap" :key="`anyMail-item-${anyMailIndex}`">
                <div class="c-inputWrap__label">
                  <label>送付先email{{anyMailIndex + 1}}</label>
                </div>
                <div class="c-inputWrap__items">
                  <div class="l-fex start">
                    <InputText
                        :value.sync="form.entrustDeliveryConfirmation.anyMailList[anyMailIndex]"
                        placeholder="入力してください"
                        tooltip="メールアドレスを入力してください"
                        :validation="validateMail"
                        :validateArg="256"
                        :isValid.sync="validate.anyMailList[anyMailIndex].isValid"
                        :errMsg.sync="validate.anyMailList[anyMailIndex].errMsg"
                        class="c-inline"
                    />
                    <div class="c-inline-block">
                      <div class="c-input__addBtn c-btn secondary delete sm" @click="removeAnyMailHandler(anyMailIndex)">
                        削除
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </template>
            <div class="c-inputWrap">
              <div
                  v-if="form.entrustDeliveryConfirmation.anyMailList.length < 5"
                  class="c-btn secondary add sm"
                  @click="addAnyMailHandler"
              >
                送付先email
              </div>
            </div>
            <div class="c-inputWrap">
              <div class="c-inputWrap__label">
                <label>QR情報1</label>
              </div>
              <div class="c-inputWrap__items">
                <InputText
                    :value.sync="form.entrustDeliveryConfirmation.qrInfo1"
                    placeholder="入力してください"
                    :validation="validateJustBlank"
                    :isValid.sync="validate.qrInfo1.isValid"
                    :errMsg.sync="validate.qrInfo1.errMsg"
                />
              </div>
            </div>
            <div class="c-inputWrap">
              <div class="c-inputWrap__label">
                <label>QR情報2</label>
              </div>
              <div class="c-inputWrap__items">
                <InputText
                    :value.sync="form.entrustDeliveryConfirmation.qrInfo2"
                    placeholder="入力してください"
                    :validation="validateJustBlank"
                    :isValid.sync="validate.qrInfo2.isValid"
                    :errMsg.sync="validate.qrInfo2.errMsg"
                />
              </div>
            </div>
            <div class="c-inputWrap">
              <div class="c-inputWrap__label">
                <label>QR情報3</label>
              </div>
              <div class="c-inputWrap__items">
                <InputText
                    :value.sync="form.entrustDeliveryConfirmation.qrInfo3"
                    placeholder="入力してください"
                    :validation="validateJustBlank"
                    :isValid.sync="validate.qrInfo3.isValid"
                    :errMsg.sync="validate.qrInfo3.errMsg"
                />
              </div>
            </div>
            <div class="c-inputWrap">
              <div class="c-inputWrap__label">
                <label>QR情報4</label>
              </div>
              <div class="c-inputWrap__items">
                <InputText
                    :value.sync="form.entrustDeliveryConfirmation.qrInfo4"
                    placeholder="入力してください"
                    :validation="validateJustBlank"
                    :isValid.sync="validate.qrInfo4.isValid"
                    :errMsg.sync="validate.qrInfo4.errMsg"
                />
              </div>
            </div>
            <div class="c-inputWrap">
              <div class="c-inputWrap__label">
                <label>QR情報5</label>
              </div>
              <div class="c-inputWrap__items">
                <InputText
                    :value.sync="form.entrustDeliveryConfirmation.qrInfo5"
                    placeholder="入力してください"
                    :validation="validateJustBlank"
                    :isValid.sync="validate.qrInfo5.isValid"
                    :errMsg.sync="validate.qrInfo5.errMsg"
                />
              </div>
            </div>
          </div>
        </div>

        <!-- 排出事業者情報 -- generateFuncs.js ======================================================== -->
        <div class="l-contents l-box">
          <div class="l-box title-wrap">
            <h3 class="c-lead icon">排出事業者情報</h3>
          </div>
          <div>
            <div class="c-inputWrap">
              <div class="c-inputWrap__label required">
                <label>事業者名</label>
              </div>
              <div class="c-inputWrap__items">
                <Selectbox
                  :value.sync="tempVal.generateCompany"
                  :validation="validateJustBlank"
                  :change="changeGenerateCompanyHandler"
                  :isValid.sync="validate.generateCompany.isValid"
                  :errMsg.sync="validate.generateCompany.errMsg"
                >
                  <option
                    v-for="item in generateCompanyList"
                    :key="'sc-' + item.id"
                    :value="item.id"
                  >
                    {{ item.name }}
                  </option>
                </Selectbox>
              </div>
            </div>

            <div class="c-inputWrap">
              <div class="c-inputWrap__label required">
                <label>事業所名</label>
              </div>
              <div class="c-inputWrap__items" :class="{ disabled: !tempVal.generateCompany }">
                <div class="c-inputWrap__items__cols wrap">
                  <div
                    class="c-checkbox"
                    v-for="gf in generateFactoryIdList"
                    :key="gf.id"
                  >
                    <input
                      :id="'formEdit-' + gf.id"
                      type="checkbox"
                      name="gf-type"
                      :value="gf"
                      v-model="tempVal.generateFactory"
                      @change="changeGenerateFactoryHandler"
                    />
                    <label class="c-checkbox__label" :for="'formEdit-' + gf.id">
                      <span class="c-checkbox__box"></span>
                      {{ gf.name }}
                    </label>
                  </div>
                </div>
                <div
                  v-if="validate.generateFactoryIdList.errMsg.length"
                  class="c-input__errMsg"
                >
                  {{ validate.generateFactoryIdList.errMsg }}
                </div>
              </div>
            </div>

            <div class="c-inputWrap">
              <div class="c-inputWrap__label required">
                <label>事業場名</label>
              </div>
              <div class="c-inputWrap__items" :class="{ disabled: !tempVal.generateFactory || !tempVal.generateFactory.length }">
                <button
                  class="c-btn secondary func add l-item"
                  v-if="tempVal.generateFactory.length"
                  @click="isGenerateStoreSelectModalShow = true"
                >
                  排出事業場選択
                </button>
                <ul>
                  <li>{{ form.generateStoreList.map(s => s.name).join("、") }}</li>
                </ul>
                <div
                  v-if="validate.generateFactoryIdList.errMsg.length"
                  class="c-input__errMsg"
                >
                  {{ validate.generateFactoryIdList.errMsg }}
                </div>
              </div>
            </div>
          </div>
        </div>

        <!-- 廃棄物情報 -- entrustWaste.js ================================================================ -->
        <div
          v-for="(entrustWaste, entrustWasteIndex) in form.entrustWasteList"
          :key="`entrust-waste-${entrustWasteIndex}`"
          class="l-contents l-area"
        >
          <div class="l-box title-wrap u-space-btwn">
            <h3 class="c-lead icon">廃棄物情報{{ entrustWasteIndex + 1 }}</h3>
            <div class="c-btn secondary sm delete"
              @click="removeWasteInfoHandler(entrustWasteIndex)"
            >
              削除
            </div>
          </div>
          <div>
            <div class="c-inputWrap">
              <div class="c-inputWrap__label required">
                <label>廃棄物区分</label>
              </div>
              <div class="c-inputWrap__items">
                <div class="c-inputWrap__items__cols wrap" :class="{ disabled: entrustWasteIndex > 0 }">
                  <div class="c-radio" v-for="wt in wasteTypeList" :key="wt.id">
                    <input
                      :id="'waste-type-' + entrustWasteIndex + '-' + wt.id"
                      type="radio"
                      :name="`waste-type-${entrustWasteIndex}`"
                      :value="wt.id"
                      v-model="entrustWaste.wasteTypeId"
                      @change="syncWasteTypeId"
                    />
                    <label
                      class="c-radio__label"
                      :for="'waste-type-' + entrustWasteIndex + '-' + wt.id"
                    >
                      <span class="c-radio__box"></span>
                      {{ wt.name }}
                    </label>
                  </div>
                </div>
              </div>
            </div>
            <div class="c-inputWrap">
              <div class="c-inputWrap__label required">
                <label>マニフェスト区分</label>
              </div>
              <div class="c-inputWrap__items">
                <div class="c-inputWrap__items__cols wrap">
                  <div
                    class="c-radio"
                    v-for="mt in manifestTypeList"
                    :key="mt.id"
                    :class="{ disabled: entrustWaste.wasteTypeId !== wasteType.INDUSTRIAL_WASTE }"
                  >
                    <input
                      :id="'manifest-type-' + entrustWasteIndex + '-' + mt.id"
                      type="radio"
                      :name="`manifest-type-${entrustWasteIndex}`"
                      :value="mt.id"
                      v-model="entrustWaste.manifestTypeId"
                    />
                    <label
                      class="c-radio__label"
                      :for="'manifest-type-' + entrustWasteIndex + '-' + mt.id"
                    >
                      <span class="c-radio__box"></span>
                      {{ mt.name }}
                    </label>
                  </div>
                </div>
              </div>
            </div>
            <div class="c-inputWrap">
              <div class="c-inputWrap__label required">
                <label>廃棄物種類</label>
              </div>
              <div class="c-inputWrap__items">
                <Selectbox
                  :value.sync="entrustWaste.jwnetItemTypeId"
                  :validation="validateJustBlank"
                  :change="
                    () =>
                      changeJwnetItemTypeHandler(entrustWaste)
                  "
                  :isValid.sync="
                    validate.wasteInfo[entrustWasteIndex].jwnetItemTypeId
                      .isValid
                  "
                  :errMsg.sync="
                    validate.wasteInfo[entrustWasteIndex].jwnetItemTypeId.errMsg
                  "
                >
                  <option
                    v-for="item in jwnetItemsOpt"
                    :key="'jwnet-' + item.id"
                    :value="item.id"
                  >
                    {{ item.name }}
                  </option>
                </Selectbox>
              </div>
            </div>
            <div class="c-inputWrap">
              <div class="c-inputWrap__label required">
                <label>CBA品目</label>
              </div>
              <div class="c-inputWrap__items" :class="{ disabled: !entrustWaste.jwnetItemTypeId }">
                <Selectbox
                  :value.sync="entrustWaste.cbaItemId"
                  :validation="validateJustBlank"
                  :change="
                    () => changeCbaItemTypeHandler(entrustWaste, entrustWasteIndex)
                  "
                  :isValid.sync="
                    validate.wasteInfo[entrustWasteIndex].cbaItemId.isValid
                  "
                  :errMsg.sync="
                    validate.wasteInfo[entrustWasteIndex].cbaItemId.errMsg
                  "
                >
                  <option
                    v-for="item in entrustWaste.cbaItemOpts"
                    :key="'jwnet-item-' + item.id"
                    :value="item.id"
                  >
                    {{ item.name }}
                  </option>
                </Selectbox>
              </div>
            </div>

            <div class="c-inputWrap">
              <div class="c-inputWrap__label required">
                <label>廃棄物名称</label>
              </div>
              <div class="c-inputWrap__items" :class="{ disabled: !entrustWaste.cbaItemId }">
                <Selectbox
                  :value.sync="entrustWaste.wasteNameId"
                  :validation="validateJustBlank"
                  :isValid.sync="
                    validate.wasteInfo[entrustWasteIndex].wasteNameId.isValid
                  "
                  :errMsg.sync="
                    validate.wasteInfo[entrustWasteIndex].wasteNameId.errMsg
                  "
                >
                  <option
                    v-for="item in entrustWaste.wasteNameOpts"
                    :key="'wn-' + item.id"
                    :value="item.id"
                  >
                    {{ item.name }}
                  </option>
                </Selectbox>
              </div>
            </div>

            <div class="c-inputWrap">
              <div class="c-inputWrap__label required">
                <label>構成種類</label>
              </div>
              <div class="c-inputWrap__items" :class="{ disabled: !entrustWaste.jwnetItemTypeId }">
                <div
                  v-for="(
                    constitutionType, constitutionIndex
                  ) in entrustWaste.constitutionItemIdList"
                  :key="constitutionIndex"
                  class="l-flex start"
                >
                  <template>
                    <Selectbox
                      v-if="constitutionIndex === 0"
                      class="l-area"
                      :value.sync="constitutionType.constitutionItemId"
                      :validation="validateJustBlank"
                      :isValid.sync="
                        validate.wasteInfo[entrustWasteIndex].constitutionItemId
                          .isValid
                      "
                      :errMsg.sync="
                        validate.wasteInfo[entrustWasteIndex].constitutionItemId
                          .errMsg
                      "
                    >
                      <option
                        v-for="item in constitutionItemList"
                        :key="'sc-' + item.id"
                        :value="item.id"
                      >
                        {{ item.name }}
                      </option>
                    </Selectbox>
                    <Selectbox
                      v-else
                      class="l-area"
                      :value.sync="constitutionType.constitutionItemId"
                      :validation="validateJustBlank"
                    >
                      <option
                        v-for="item in constitutionItemList"
                        :key="'sc-' + item.id"
                        :value="item.id"
                      >
                        {{ item.name }}
                      </option>
                    </Selectbox>
                  </template>
                  <div
                    v-if="constitutionIndex"
                    class="c-input__addBtn c-btn secondary delete sm"
                    @click="
                      removeConstitutionHandler(entrustWaste, constitutionType)
                    "
                  >
                    削除
                  </div>
                </div>
                <div
                  class="c-btn secondary add sm"
                  @click="addConstitutionHandler(entrustWaste)"
                >
                  構成種類を追加
                </div>
              </div>
            </div>

            <div class="c-inputWrap">
              <div class="c-inputWrap__label">
                <label>予定数量</label>
              </div>
              <div class="c-inputWrap__items">
                <div class="c-inputWrap__items__cols">
                  <InputText
                    :value.sync="entrustWaste.plansQuantity"
                    placeholder="予定数量"
                    tooltip="整数5桁、小数点1桁、小数部3桁で入力してください"
                    :validation="validateQuantity"
                    :isValid.sync="validate.wasteInfo[entrustWasteIndex].plansQuantity.isValid"
                    :errMsg.sync="validate.wasteInfo[entrustWasteIndex].plansQuantity.errMsg"
                  />
                  <Selectbox
                    class="sm"
                    :value.sync="entrustWaste.plansWasteUnitTypeId"
                    :validation="validateJustBlank"
                  >
                    <option :value="null"></option>
                    <option
                      v-for="opt in wasteUnitOpt"
                      :key="'unit-opt-1-' + opt.id"
                      :value="opt.id"
                    >
                      {{ opt.name }}
                    </option>
                  </Selectbox>
                  <span>/</span>
                  <Selectbox
                    class="sm"
                    :value.sync="entrustWaste.plansWasteTermTypeId"
                    :validation="validateJustBlank"
                  >
                    <option :value="null"></option>
                    <option
                      v-for="term in termTypes"
                      :key="'term-1-' + term.id"
                      :value="term.id"
                    >
                      {{ term.name }}
                    </option>
                  </Selectbox>
                </div>
              </div>
            </div>
            <div class="c-inputWrap">
              <div class="c-inputWrap__label">
                <label>上限数量</label>
              </div>
              <div class="c-inputWrap__items">
                <div class="c-inputWrap__items__cols">
                  <InputText
                    :value.sync="entrustWaste.limitQuantity"
                    placeholder="上限数量"
                    tooltip="半角数値で入力してください"
                    :validation="validateNumber"
                  />
                  <Selectbox
                    class="sm"
                    :value.sync="entrustWaste.limitWasteUnitTypeId"
                    :validation="validateJustBlank"
                  >
                    <option :value="null"></option>
                    <option
                      v-for="opt in wasteUnitOpt"
                      :key="'unit-opt-2-' + opt.id"
                      :value="opt.id"
                    >
                      {{ opt.name }}
                    </option>
                  </Selectbox>
                  <span>/</span>
                  <Selectbox
                    class="sm"
                    :value.sync="entrustWaste.limitWasteTermTypeId"
                    :validation="validateJustBlank"
                  >
                    <option :value="null"></option>
                    <option
                      v-for="term in termTypes"
                      :key="'term-2-' + term.id"
                      :value="term.id"
                    >
                      {{ term.name }}
                    </option>
                  </Selectbox>
                </div>
              </div>
            </div>

            <div class="c-inputWrap">
              <div class="c-inputWrap__label required">
                <label>荷姿</label>
              </div>
              <div class="c-inputWrap__items">
                <div
                  v-for="(
                    shapeType, shapeIndex
                  ) in entrustWaste.shapeTypeIdList"
                  :key="shapeIndex"
                  class="l-flex start"
                >
                  <template>
                    <Selectbox
                      v-if="shapeIndex === 0"
                      class="l-area"
                      :value.sync="shapeType.shapeTypeId"
                      :validation="validateJustBlank"
                      :isValid.sync="
                        validate.wasteInfo[entrustWasteIndex].shapeTypeId
                          .isValid
                      "
                      :errMsg.sync="
                        validate.wasteInfo[entrustWasteIndex].shapeTypeId.errMsg
                      "
                    >
                      <option
                        v-for="shape in shapeOpt"
                        :key="'shape-' + shapeIndex + '-' + shape.id"
                        :value="shape.id"
                      >
                        {{ shape.name }}
                      </option>
                    </Selectbox>
                    <Selectbox
                      v-else
                      class="l-area"
                      :value.sync="shapeType.shapeTypeId"
                      :validation="validateJustBlank"
                    >
                      <option
                        v-for="shape in shapeOpt"
                        :key="'shape-' + shapeIndex + '-' + shape.id"
                        :value="shape.id"
                      >
                        {{ shape.name }}
                      </option>
                    </Selectbox>
                  </template>
                  <div
                    v-if="shapeIndex"
                    class="c-input__addBtn c-btn secondary delete sm"
                    @click="removeShapeHandler(entrustWaste, shapeType)"
                  >
                    削除
                  </div>
                </div>
                <div
                  class="c-btn secondary add sm"
                  @click="addShapeHandler(entrustWaste)"
                >
                  荷姿を追加
                </div>
              </div>
            </div>

            <div class="c-inputWrap">
              <div class="c-inputWrap__label">
                <label>処分方法</label>
              </div>
              <div class="c-inputWrap__items">
                <div
                  v-for="(
                    wayType, wayTypeIndex
                  ) in entrustWaste.disposalWayType"
                  :key="wayTypeIndex"
                  class="l-flex start"
                >
                  <template>
                    <Selectbox
                      v-if="wayTypeIndex === 0"
                      class="l-area"
                      :value.sync="wayType.disposalWayTypeId"
                      :validation="validateJustBlank"
                      :isValid.sync="
                        validate.wasteInfo[entrustWasteIndex].disposalWayTypeId
                          .isValid
                      "
                      :errMsg.sync="
                        validate.wasteInfo[entrustWasteIndex].disposalWayTypeId
                          .errMsg
                      "
                    >
                      <option :value="null"></option>
                      <option
                        v-for="wayType in disposalWayTypes"
                        :key="'way-' + wayTypeIndex + '-' + wayType.id"
                        :value="wayType.id"
                      >
                        {{ wayType.name }}
                      </option>
                    </Selectbox>
                    <Selectbox
                      v-else
                      class="l-area"
                      :value.sync="wayType.disposalWayTypeId"
                      :validation="validateJustBlank"
                    >
                      <option :value="null"></option>
                      <option
                        v-for="wayType in disposalWayTypes"
                        :key="'way-' + wayTypeIndex + '-' + wayType.id"
                        :value="wayType.id"
                      >
                        {{ wayType.name }}
                      </option>
                    </Selectbox>
                  </template>
                  <div
                    v-if="wayTypeIndex"
                    class="c-input__addBtn c-btn secondary delete sm"
                    @click="removeWayTypeHandler(entrustWaste, wayType)"
                  >
                    削除
                  </div>
                </div>
                <div
                  class="c-btn secondary add sm"
                  @click="addWayTypeHandler(entrustWaste)"
                >
                  処分方法を追加
                </div>
              </div>
            </div>

            <div class="c-inputWrap">
              <div class="c-inputWrap__label">
                <label>適正処理に必要な情報</label>
              </div>
              <div class="c-inputWrap__items">
                <MyTextarea
                  :value.sync="entrustWaste.processInfo"
                  rows="6"
                  placeholder="入力してください"
                  :validation="validateJustBlank"
                ></MyTextarea>
              </div>
            </div>
            <div class="c-inputWrap">
              <div class="c-inputWrap__label">
                <label>WDS</label>
              </div>
              <div class="c-inputWrap__items">
                <input :id="'file-upload-' + entrustWasteIndex"
                       type="file"
                       @change="(e) => uploadWDSFile(e, entrustWasteIndex)"
                />
                <label
                  class="c-btn secondary upload sm"
                  :for="'file-upload-' + entrustWasteIndex"
                >
                  WDSアップロード
                </label>
                {{ entrustWaste.wdsFileName }}
              </div>
            </div>
          </div>
        </div>
        <div class="l-box">
          <button class="c-btn secondary func add" @click="addWasteInfoHandler">
            廃棄物情報を追加
          </button>
        </div>

        <!-- 運搬委託契約先情報 -- entrustCollect.js ======================================== -->
        <div
          v-for="(
            entrustCollect, entrustCollectIndex
          ) in form.entrustCollectCompanyInfo"
          :key="`entrust-collect-${entrustCollectIndex}`"
          class="l-contents l-area"
        >
          <div class="l-box title-wrap u-space-btwn">
            <h3 class="c-lead icon">運搬委託契約先情報{{ entrustCollectIndex + 1 }}</h3>
            <div class="c-btn secondary sm delete"
              @click="
                removeCollectInfoHandler(entrustCollectIndex)
              "
            >
              削除
            </div>
          </div>
          <div>
            <div class="c-inputWrap">
              <div class="c-inputWrap__label required">
                <label>運搬事業者</label>
              </div>
              <div class="c-inputWrap__items">
                <v-autocomplete ref="collect_company_autocomplete"
                                :isValid="validate.entrustCollectCompanyInfo[entrustCollectIndex].collectCompanyId.isValid"
                                :errMsg="validate.entrustCollectCompanyInfo[entrustCollectIndex].collectCompanyId.errMsg"
                                @change="s => {onChange(s, `collect_company_autocomplete`)}"
                                class="c-input"
                                :items="selectCollectCompanyList[entrustCollectIndex]"
                                :min-len="0" :wait="300"
                                @update-items='(text) => updateCollectCompany(entrustCollectIndex, text)'
                                v-model="selectedCollectCompany[entrustCollectIndex]"
                                :component-item='selectTemplate'
                                :auto-select-one-item="autoSelect" :get-label='getLabel'>
                </v-autocomplete>
              </div>
            </div>

            <div class="c-inputWrap" v-if="form.entrustWasteList.length > 0 && form.entrustWasteList[0].wasteTypeId === wasteType.INDUSTRIAL_WASTE">
              <div class="c-inputWrap__label">
                <label>運搬許可証番号</label>
              </div>
              <div class="c-inputWrap__items">
                <button
                  class="c-btn secondary func add l-item"
                  :class="{
                    disabled: !entrustCollect.collectCompanyId,
                  }"
                  @click="clickShowCollectLicenceModalHandler(entrustCollect)"
                >
                  運搬許可証番号選択
                </button>
                <ul
                  v-if="entrustCollect.collectLicenceInfo.length"
                  class="c-commaList"
                >
                  <li
                    v-for="cl in entrustCollect.collectLicenceInfo"
                    :key="cl.id"
                  >
                    {{ cl.name }}
                  </li>
                </ul>
              </div>
            </div>
            <div class="c-inputWrap" v-if="form.entrustWasteList.length > 0 && form.entrustWasteList[0].wasteTypeId === wasteType.COMMON_WASTE">
              <div class="c-inputWrap__label">
                <label>運搬許可証番号</label>
              </div>
              <div class="c-inputWrap__items">
                <button
                  class="c-btn secondary func add l-item"
                  :class="{
                    disabled: !entrustCollect.collectCompanyId,
                  }"
                  @click="clickShowCollectCommonLicenceModalHandler(entrustCollect)"
                >
                  運搬許可証番号選択
                </button>
                <ul
                  v-if="entrustCollect.commonWasteCollectLicenceInfo.length"
                  class="c-commaList"
                >
                  <li
                    v-for="cwcl in entrustCollect.commonWasteCollectLicenceInfo"
                    :key="cwcl.id"
                  >
                    {{ cwcl.name }}
                  </li>
                </ul>
              </div>
            </div>

            <div class="c-inputWrap">
              <div class="c-inputWrap__label required">
                <label>契約開始日</label>
              </div>
              <div class="c-inputWrap__items">
                <InputDate
                  :value.sync="entrustCollect.contractStartDate"
                  placeholder="選択してください"
                  :validation="validateJustBlank"
                  :isValid.sync="
                    validate.entrustCollectCompanyInfo[entrustCollectIndex]
                      .contractStartDate.isValid
                  "
                  :errMsg.sync="
                    validate.entrustCollectCompanyInfo[entrustCollectIndex]
                      .contractStartDate.errMsg
                  "
                />
              </div>
            </div>
            <div class="c-inputWrap">
              <div class="c-inputWrap__label required">
                <label>契約終了日</label>
              </div>
              <div class="c-inputWrap__items">
                <InputDate
                  :value.sync="entrustCollect.contractEndDate"
                  placeholder="選択してください"
                  :validation="validateJustBlank"
                  :isValid.sync="
                    validate.entrustCollectCompanyInfo[entrustCollectIndex]
                      .contractEndDate.isValid
                  "
                  :errMsg.sync="
                    validate.entrustCollectCompanyInfo[entrustCollectIndex]
                      .contractEndDate.errMsg
                  "
                />
              </div>
            </div>
            <div class="c-inputWrap">
              <div class="c-inputWrap__label required">
                <label>契約自動更新</label>
              </div>
              <div class="c-inputWrap__items">
                <div class="c-inputWrap__items__cols">
                  <div class="c-radio">
                    <input
                      :id="`collect-auto-update-${entrustCollectIndex}-true`"
                      type="radio"
                      :name="`collect-auto-update-${entrustCollectIndex}`"
                      :value="true"
                      v-model="entrustCollect.isAutoUpdate"
                    />
                    <label
                      class="c-radio__label"
                      :for="`collect-auto-update-${entrustCollectIndex}-true`"
                    >
                      <span class="c-radio__box"></span>
                      有
                    </label>
                  </div>
                  <div class="c-radio">
                    <input
                      :id="`collect-auto-update-${entrustCollectIndex}-false`"
                      type="radio"
                      :name="`collect-auto-update-${entrustCollectIndex}`"
                      :value="false"
                      v-model="entrustCollect.isAutoUpdate"
                    />
                    <label
                      class="c-radio__label"
                      :for="`collect-auto-update-${entrustCollectIndex}-false`"
                    >
                      <span class="c-radio__box"></span>
                      無
                    </label>
                  </div>
                </div>
              </div>
            </div>

            <div class="c-inputWrap">
              <div class="c-inputWrap__label">
                <label>運搬単価</label>
              </div>
              <div class="c-inputWrap__items">
                <input
                    value=null
                    :id="'csv-file-' + entrustCollectIndex"
                    type="file"
                    accept="text/csv"
                    @change="(e) => fileChangeCollect(e, entrustCollectIndex)"
                    @click="(e) => e.target.value = null"
                />
                <label
                    class="c-btn secondary upload sm"
                    :for="'csv-file-' + entrustCollectIndex"
                >
                  CSVアップロード
                </label>
                <div
                    v-if="entrustCollect.collectUnitPriceFileName"
                    class="l-flex start"
                >
                  <div class="l-area contract-file">
                    {{entrustCollect.collectUnitPriceFileName}}
                  </div>
                  <label
                      class="c-btn secondary delete sm contract-file-delete-button"
                      @click="removeCollectUnitPriceFile(entrustCollectIndex)"
                  >
                    削除
                  </label>
                </div>
                <template v-if="csvCollectErrorMsgs.length > 0 && csvCollectErrorMsgs[entrustCollectIndex]">
                  <p v-for="(msg, i) in csvCollectErrorMsgs[entrustCollectIndex]" :key="'collect-err-msg-' + i" class="c-input__errMsg">{{ msg }}</p>
                </template>
              </div>
            </div>

            <div class="c-inputWrap">
              <div class="c-inputWrap__label">
                <label>契約書PDF</label>
              </div>
              <div class="c-inputWrap__items">
                <input
                  :id="`collect-pdf-upload-${entrustCollectIndex}`"
                  type="file"
                  accept="application/pdf"
                  @change="(e) => uploadCollectContractPdf(e, entrustCollectIndex)"
                />
                <label
                  class="c-btn secondary upload sm"
                  :for="`collect-pdf-upload-${entrustCollectIndex}`"
                >
                  PDFアップロード
                </label>
                <div
                    v-for="(file, index) in entrustCollect.entrustCollectContractFileList"
                    :key="file.contractFileName"
                    class="l-flex start"
                >
                  <div class="l-area contract-file">
                    {{file.contractFileName}}
                  </div>
                  <label
                    class="c-btn secondary delete sm contract-file-delete-button"
                    @click="removeContractFile(entrustCollectIndex, index)"
                  >
                    削除
                  </label>
                </div>
              </div>
            </div>
            <div class="c-inputWrap">
              <div class="c-inputWrap__label">
                <label>備考</label>
              </div>
              <div class="c-inputWrap__items">
                <MyTextarea
                  :value.sync="entrustCollect.remarks"
                  rows="6"
                  placeholder="入力してください"
                  :validation="validateJustBlank"
                ></MyTextarea>
              </div>
            </div>
          </div>
        </div>
        <div class="l-box"
          v-if="form.entrustWasteList.length > 0 && form.entrustWasteList[0].wasteTypeId === wasteType.INDUSTRIAL_WASTE
            && form.entrustCollectCompanyInfo.length < 5
            || form.entrustCollectCompanyInfo.length == 0">
          <button
            class="c-btn secondary func add"
            @click="addCollectInfoHandler"
          >
            運搬委託契約先情報を追加
          </button>
        </div>

        <!-- 処分委託契約先情報  -- disposal.js ======================================== -->
        <div class="l-contents l-box">
          <div class="l-box title-wrap">
            <h3 class="c-lead icon">処分委託契約先情報</h3>
          </div>
          <div>
            <div class="c-inputWrap">
              <div class="c-inputWrap__label required">
                <label>処分事業者</label>
              </div>
              <div class="c-inputWrap__items">
                <v-autocomplete ref="disposal_company_autocomplete"
                                :isValid="validate.disposalCompanyId.isValid"
                                :errMsg="validate.disposalCompanyId.errMsg"
                                @change="s => {onChange(s, `disposal_company_autocomplete`)}"
                                class="c-input"
                                :items="this.selectDisposalCompanyList"
                                :min-len="0" :wait="300"
                                @update-items='updateDisposalCompany'
                                v-model="selectedDisposalCompany"
                                :component-item='selectTemplate'
                                :auto-select-one-item="this.autoSelect" :get-label='getLabel'>
                </v-autocomplete>
              </div>
            </div>
            <div class="c-inputWrap" v-if="form.entrustWasteList.length > 0 && form.entrustWasteList[0].wasteTypeId === wasteType.INDUSTRIAL_WASTE">
              <div class="c-inputWrap__label">
                <label>処分許可証番号</label>
              </div>
              <div class="c-inputWrap__items">
                <button
                  class="c-btn secondary func add l-item"
                  :class="{
                    disabled:
                      !form.entrustDisposalCompanyInfo.disposalCompanyId,
                  }"
                  @click="isDisposalLicenceSelectModalShow = true"
                >
                  処分許可証番号選択
                </button>
                <ul
                  v-if="
                    form.entrustDisposalCompanyInfo.disposalLicenceInfo.length
                  "
                  class="c-commaList"
                >
                  <li
                    v-for="gs in form.entrustDisposalCompanyInfo
                      .disposalLicenceInfo"
                    :key="gs.id"
                  >
                    {{ gs.name }}
                  </li>
                </ul>
              </div>
            </div>
            <div class="c-inputWrap" v-if="form.entrustWasteList.length > 0 && form.entrustWasteList[0].wasteTypeId === wasteType.COMMON_WASTE">
              <div class="c-inputWrap__label">
                <label>処分許可証番号</label>
              </div>
              <div class="c-inputWrap__items">
                <button
                  class="c-btn secondary func add l-item"
                  :class="{
                    disabled:
                      !form.entrustDisposalCompanyInfo.disposalCompanyId,
                  }"
                  @click="isDisposalCommonLicenceSelectModalShow = true"
                >
                  処分許可証番号選択
                </button>
                <ul
                  v-if="
                    form.entrustDisposalCompanyInfo.commonWasteDisposalLicenceInfo.length
                  "
                  class="c-commaList"
                >
                  <li
                    v-for="cwdl in form.entrustDisposalCompanyInfo
                      .commonWasteDisposalLicenceInfo"
                    :key="cwdl.id"
                  >
                    {{ cwdl.name }}
                  </li>
                </ul>
              </div>
            </div>
            <div class="c-inputWrap">
              <div class="c-inputWrap__label required">
                <label>契約開始日</label>
              </div>
              <div class="c-inputWrap__items">
                <InputDate
                  :value.sync="
                    form.entrustDisposalCompanyInfo.contractStartDate
                  "
                  placeholder="選択してください"
                  :validation="validateJustBlank"
                  :isValid.sync="validate.contractStartDate.isValid"
                  :errMsg.sync="validate.contractStartDate.errMsg"
                />
              </div>
            </div>
            <div class="c-inputWrap">
              <div class="c-inputWrap__label required">
                <label>契約終了日</label>
              </div>
              <div class="c-inputWrap__items">
                <InputDate
                  :value.sync="form.entrustDisposalCompanyInfo.contractEndDate"
                  placeholder="選択してください"
                  :validation="validateJustBlank"
                  :isValid.sync="validate.contractEndDate.isValid"
                  :errMsg.sync="validate.contractEndDate.errMsg"
                />
              </div>
            </div>
            <div class="c-inputWrap">
              <div class="c-inputWrap__label required">
                <label>契約自動更新</label>
              </div>
              <div class="c-inputWrap__items">
                <div class="c-inputWrap__items__cols">
                  <div class="c-radio">
                    <input
                      id="auto-update-true"
                      type="radio"
                      name="auto-update"
                      :value="true"
                      v-model="form.entrustDisposalCompanyInfo.isAutoUpdate"
                    />
                    <label class="c-radio__label" for="auto-update-true">
                      <span class="c-radio__box"></span>
                      有
                    </label>
                  </div>
                  <div class="c-radio">
                    <input
                      id="auto-update-false"
                      type="radio"
                      name="auto-update"
                      :value="false"
                      v-model="form.entrustDisposalCompanyInfo.isAutoUpdate"
                    />
                    <label class="c-radio__label" for="auto-update-false">
                      <span class="c-radio__box"></span>
                      無
                    </label>
                  </div>
                </div>
              </div>
            </div>
            <div class="c-inputWrap">
              <div class="c-inputWrap__label">
                <label>処分単価</label>
              </div>
              <div class="c-inputWrap__items">
                <input
                    value=null
                    :id="'disposal-csv-file'"
                    type="file"
                    accept="text/csv"
                    @change="(e) => fileChangeDisposal(e)"
                    @click="(e) => e.target.value = null"
                />
                <label
                    class="c-btn secondary upload sm"
                    :for="'disposal-csv-file'"
                >
                  CSVアップロード
                </label><div
                  v-if="form.entrustDisposalCompanyInfo.disposalUnitPriceFileName"
                  class="l-flex start"
              >
                <div class="l-area contract-file">
                  {{ form.entrustDisposalCompanyInfo.disposalUnitPriceFileName }}
                </div>
                <label
                    class="c-btn secondary delete sm contract-file-delete-button"
                    @click="removeDisposalUnitPriceFile()"
                >
                  削除
                </label>
              </div>
              <template v-if="csvDisposalErrorMsgs.length > 0">
                <p v-for="(msg, i) in csvDisposalErrorMsgs" :key="'disposal-err-msg-' + i" class="c-input__errMsg">{{ msg }}</p>
              </template>
              </div>
            </div>
            <div class="c-inputWrap">
              <div class="c-inputWrap__label">
                <label>契約書PDF</label>
              </div>
              <div class="c-inputWrap__items">
                <input
                    id="disposal-pdf-upload"
                    type="file"
                    accept="application/pdf"
                    @change="(e) => uploadDisposalContractPdf(e)"
                />
                <label
                    class="c-btn secondary upload sm"
                    for="disposal-pdf-upload"
                >
                  PDFアップロード
                </label>
                <div
                    v-for="(file, index) in form.entrustDisposalCompanyInfo.entrustDisposalContractFileList"
                    :key="file.contractFileName"
                    class="l-flex start"
                >
                <div class="l-area contract-file">
                  {{file.contractFileName}}
                </div>
                    <label
                      class="c-btn secondary delete sm contract-file-delete-button"
                      @click="removeDisposalContractFile(index)"
                    >
                      削除
                    </label>
                </div>
              </div>
            </div>
            <div class="c-inputWrap">
              <div class="c-inputWrap__label">
                <label>備考</label>
              </div>
              <div class="c-inputWrap__items">
                <MyTextarea
                  :value.sync="form.entrustDisposalCompanyInfo.remarks"
                  rows="6"
                  placeholder="入力してください"
                  :validation="validateJustBlank"
                ></MyTextarea>
              </div>
            </div>
          </div>
        </div>

        <!-- 経路情報  -- collectRoute.js ======================================== -->
        <div class="l-contents l-box">
          <div class="l-box title-wrap">
            <h3 class="c-lead icon">経路情報</h3>
          </div>
          <div
            v-for="(
              entrustCollect, entrustCollectRouteIndex
            ) in form.entrustCollectCompanyInfo"
            :key="`entrust-collect-${entrustCollectRouteIndex}`"
            class="l-box"
          >
            <div class="c-inputWrap sub-title">
              <div>
                <label>区間{{ entrustCollect.sectionNo }}</label>
              </div>
            </div>
            <!-- １区間目用 -->
            <div class="c-inputWrap" v-if="entrustCollectRouteIndex === 0">
              <div class="c-inputWrap__label required">
                <label>積み込み場所</label>
              </div>
              <div class="c-inputWrap__items">
                <ul>
                  <li>{{ form.generateStoreList.map(s => s.name).join("、") }}</li>
                </ul>
              </div>
            </div>
            <!-- 2区間目以後用 -->
            <div class="c-inputWrap" v-else-if="entrustCollectRouteIndex > 0 && form.entrustCollectCompanyInfo[entrustCollectRouteIndex - 1].collectLicenceInfo.length > 0">
              <div class="c-inputWrap__label required">
                <label>積み込み場所</label>
              </div>
              <div class="c-inputWrap__items">
                <div>
                  {{ getShipmentLabel(entrustCollectRouteIndex) }}
                </div>
              </div>
            </div>
            <div class="c-inputWrap">
              <div class="c-inputWrap__label required">
                <label>運搬会社/事務所</label>
              </div>
              <div class="c-inputWrap__items" :class="{ disabled: !entrustCollect.collectCompanyId }">
                <Selectbox v-if="form.entrustWasteList.length > 0 &&  form.entrustWasteList[0].wasteTypeId === wasteType.INDUSTRIAL_WASTE"
                  :value.sync="entrustCollect.collectFactoryId"
                  :validation="validateJustBlank"
                  :isValid.sync="
                    validate.entrustCollectCompanyInfo[entrustCollectRouteIndex]
                      .collectFactoryId.isValid
                  "
                  :errMsg.sync="
                    validate.entrustCollectCompanyInfo[entrustCollectRouteIndex]
                      .collectFactoryId.errMsg
                  "
                >
                  <option
                    v-for="opt in entrustCollect.companyOpts"
                    :key="'collect-opt-' + opt.id"
                    :value="opt.id"
                  >
                    {{ opt.name }} - JWNETID({{ opt.jwnetId }})
                  </option>
                </Selectbox>
                <Selectbox v-else
                  :value.sync="entrustCollect.collectFactoryId"
                  :validation="validateJustBlank"
                  :isValid.sync="
                    validate.entrustCollectCompanyInfo[entrustCollectRouteIndex]
                      .collectFactoryId.isValid
                  "
                  :errMsg.sync="
                    validate.entrustCollectCompanyInfo[entrustCollectRouteIndex]
                      .collectFactoryId.errMsg
                  "
                >
                  <option
                    v-for="opt in entrustCollect.companyOpts"
                    :key="'collect-opt-' + opt.id"
                    :value="opt.id"
                  >
                    {{ opt.name }}
                  </option>
                </Selectbox>
              </div>
            </div>
            <div class="c-inputWrap">
              <div class="c-inputWrap__label required">
                <label>運搬方法</label>
              </div>
              <div class="c-inputWrap__items">
                <Selectbox
                  :value.sync="entrustCollect.collectWayTypeId"
                  :validation="validateJustBlank"
                  :isValid.sync="
                    validate.entrustCollectCompanyInfo[entrustCollectRouteIndex]
                      .collectWayTypeId.isValid
                  "
                  :errMsg.sync="
                    validate.entrustCollectCompanyInfo[entrustCollectRouteIndex]
                      .collectWayTypeId.errMsg
                  "
                >
                  <option
                    v-for="cwt in collectWayTypeList"
                    :key="'cwt-' + cwt.id"
                    :value="cwt.id"
                  >
                    {{ cwt.name }}
                  </option>
                </Selectbox>
              </div>
            </div>

            <!-- 最後の区間は処分区間情報 -->
            <div class="c-inputWrap" v-if="entrustCollectRouteIndex === (form.entrustCollectCompanyInfo.length - 1)">
              <div class="c-inputWrap__label required">
                <label>運搬先</label>
              </div>
              <div class="c-inputWrap__items">
                <div class="c-inputWrap__items__cols l-area">
                  {{ destinationTypeOpts.filter(d => d.id === entrustCollect.destinationTypeId)[0].name }}
                </div>
                <template v-if="entrustCollect.destinationTypeId === destinationType.DISPOSAL_SITE">
                  <Selectbox
                      :change="changeDisposalSite"
                      :class="{ disabled: !disposalSiteOpts.length }"
                      :value.sync="form.entrustDisposalCompanyInfo.disposalSiteId"
                      :validation="validateJustBlank"
                      :isValid.sync="validate.disposalSiteId.isValid"
                      :errMsg.sync="validate.disposalSiteId.errMsg"
                  >
                    <option
                        v-for="site in disposalSiteOpts"
                        :key="'site-' + site.id"
                        :value="site.id"
                    >
                      {{ site.name }} - JWNETID({{ site.jwnetId }}) - 事業場コード({{ site.storeCode }})
                    </option>
                  </Selectbox>
                </template>
              </div>
            </div>

            <!-- 積み替え保管区間の情報 -->
            <div class="c-inputWrap" v-else-if="entrustCollectRouteIndex !== (form.entrustCollectCompanyInfo.length - 1) && entrustCollect.collectLicenceInfo.length > 0">
              <div class="c-inputWrap__label required">
                <label>運搬先</label>
              </div>
              <div class="c-inputWrap__items">
                <div class="c-inputWrap__items__cols l-area">
                  {{ destinationTypeOpts.filter(d => d.id === entrustCollect.destinationTypeId)[0].name }}
                </div>
                <template v-if="entrustCollect.destinationTypeId === destinationType.TRANSSHIPMENT">
                  <Selectbox
                      :value.sync="entrustCollect.transshipmentStorageId"
                      :validation="validateJustBlank"
                      :isValid.sync="validate.entrustCollectCompanyInfo[entrustCollectRouteIndex].transshipmentStorageId.isValid"
                      :errMsg.sync="validate.entrustCollectCompanyInfo[entrustCollectRouteIndex].transshipmentStorageId.errMsg"
                  >
                    <option
                        v-for="shipOpt in entrustCollect.transShipmentOpts"
                        :key="'ship-' + entrustCollectRouteIndex + '-' + shipOpt.id"
                        :value="shipOpt.id"
                    >
                      {{ shipOpt.name }} - JWNETID({{ shipOpt.jwnetId }}) - 事業場コード({{ shipOpt.storeCode }})
                    </option>
                  </Selectbox>
                </template>
              </div>
            </div>
          </div>
        </div>

        <!-- 最終処分場情報 ======================================== -->
        <div class="l-contents l-box">
          <div class="l-box title-wrap">
            <h3 class="c-lead icon">最終処分場情報</h3>
          </div>
          <div class="c-inputWrap">
            <div class="c-inputWrap__label">
              <label>最終処分場</label>
            </div>
            <div class="c-inputWrap__items">
              <div class="c-btn secondary func small l-item" @click="isFinalDisposalSiteSelectListModalShow = true;" :class="{ disabled: form.entrustDisposalCompanyInfo.disposalSiteId == null}">最終処分場情報設定</div>
            </div>
          </div>
          <template v-for="item in form.entrustDisposalCompanyInfo.finalDisposalSiteList">
            <div v-if="null !== item.finalDisposalSiteId" class="c-disposalSiteList__item" :key="item.finalDisposalSiteId">
              <span class="name">{{ item.finalDisposalSiteName }}</span>
              <span class="address">住所 : {{ item.addressInfo.prefecturesName }} {{ item.addressInfo.address }} {{ item.addressInfo.buildingName }}</span>
              <span class="code">事業所コード : {{ item.finalDisposalStoreCode }}</span>
            </div>
          </template>
        </div>
      </div>

      <div class="l-btns l-container">
        <div class="left">
          <router-link to="/entrust" class="c-btn secondary small back">一覧へ戻る</router-link>
        </div>
        <div class="center">
          <div class="c-btn primary mid" @click="formHandler(false)">登録</div>
          <div class="c-btn primary" @click="formHandler(true)">登録してルート一覧へ</div>
        </div>
      </div>
    </main>

    <!-- 排出事業場設定モーダル -->
    <GenerateStoreSelectModal
      :isShow.sync="isGenerateStoreSelectModalShow"
      :generateFactory="tempVal.generateFactory"
      @setSelectedStores="setSelectedStores"
    />

    <!-- 運搬許可証設定設定モーダル -->
    <CollectLicenceSelectModal
      :isShow.sync="isCollectLicenceSelectModalShow"
      :targetEntrustCollect="targetEntrustCollect"
      :targetSelectedLicense="targetSelectedLicense"
      @setSelectedCollectLicence="setCollectLicence"
    />

    <!-- 運搬許可証設定設定モーダル -->
    <CollectCommonLicenceSelectModal
      :isShow.sync="isCollectCommonLicenceSelectModalShow"
      :targetEntrustCollect="targetEntrustCollect"
      @setSelectedCollectLicence="setCollectCommonLicence"
    />

    <!-- 処分許可証設定設定モーダル -->
    <DisposalLicenceSelectModal
      :isShow.sync="isDisposalLicenceSelectModalShow"
      :disposalCompanyId="form.entrustDisposalCompanyInfo.disposalCompanyId"
      @setSelectedDisposalLicence="setDisposalLicence"
    />

    <!-- 処分許可証設定設定モーダル -->
    <DisposalCommonLicenceSelectModal
      :isShow.sync="isDisposalCommonLicenceSelectModalShow"
      :disposalCompanyId="form.entrustDisposalCompanyInfo.disposalCompanyId"
      @setSelectedDisposalLicence="setDisposalCommonLicence"
    />

    <!-- 最終処分場設定モーダル -->
    <FinalDisposalSiteSelectListModal
      :isShow.sync="isFinalDisposalSiteSelectListModalShow"
      :form.sync="form.entrustDisposalCompanyInfo.finalDisposalSiteList"
      :disposalSiteId="form.entrustDisposalCompanyInfo.disposalSiteId"
    />

    <!-- 登録確認モーダル -->
    <div class="c-modal" v-show="isConfirmModalShow">
      <div class="c-modal__overlay"></div>
      <div class="c-modal__content">
        <div class="c-modal__body">
          委託契約書を登録します。<br />
          よろしいですか？
        </div>
        <ModalFooterButtons :processing="processing">
          <div class="c-btn secondary small" @click="isConfirmModalShow = false">戻る</div>
          <div class="c-btn primary small" @click="submit">はい</div>
        </ModalFooterButtons>
      </div>
    </div>

    <AppFooter />
  </div>
</template>

<style scoped>
.contract-file {
  margin-top: 2rem;
  white-space: pre-wrap;
  word-break: break-all;
  width: 40rem;
}
.contract-file-delete-button {
  align-self: center;
}
</style>

<script>
import axios from "axios";
import InputText from "@/components/parts/input/InputText";
import Selectbox from "@/components/parts/input/Selectbox";
import InputDate from "@/components/parts/input/InputDate";
import MyTextarea from "@/components/parts/input/MyTextarea";
import GenerateStoreSelectModal from "./components/GenerateStoreSelectModal";
import DisposalLicenceSelectModal from "./components/DisposalLicenceSelectModal";
import CollectLicenceSelectModal from "./components/CollectLicenceSelectModal";
import DisposalCommonLicenceSelectModal from "./components/DisposalCommonLicenceSelectModal";
import CollectCommonLicenceSelectModal from "./components/CollectCommonLicenceSelectModal";
import validation from "@/mixin/validation";
import callApi from "./mixins/callApi";
import formValue from "./mixins/formValue";
import validate from "./mixins/validate";
import generateFuncs from "./mixins/generateFuncs";
import entrustWaste from "./mixins/entrustWaste";
import disposal from "./mixins/disposal";
import entrustCollect from "./mixins/entrustCollect";
import collectRoute from "./mixins/collectRoute";
import { API_URL, DOMAIN, COOKIE_INFO } from "@/const/index";
import pdfUpload from "./mixins/pdfUpload";
import ModalFooterButtons from "@/components/layout/ModalFooterButtons";
import FinalDisposalSiteSelectListModal from "./components/FinalDisposalSiteSelectListModal";
import csvUploadCollect from "@/pages/entrust/common/csvUploadCollect";
import csvUploadDisposal from "@/pages/entrust/common/csvUploadDisposal";
import anyMail from "@/pages/entrust/common/anyMail";
import common from "@/mixin/common";
import countByte from "@/pages/entrust/common/countByte";
import selectionLocalStorage from "@/mixin/selectionLocalStorage";
import SelectItemTemplate from "@/components/parts/input/SelectItemTemplate";

export default {
  name: "entrust-input",
  components: {
    ModalFooterButtons,
    InputText,
    Selectbox,
    InputDate,
    MyTextarea,
    GenerateStoreSelectModal,
    DisposalLicenceSelectModal,
    CollectLicenceSelectModal,
    DisposalCommonLicenceSelectModal,
    CollectCommonLicenceSelectModal,
    FinalDisposalSiteSelectListModal,
  },
  mixins: [
    validation,
    callApi,
    formValue,
    validate,
    generateFuncs,
    entrustWaste,
    disposal,
    entrustCollect,
    collectRoute,
    pdfUpload,
    csvUploadCollect,
    csvUploadDisposal,
    anyMail,
    common,
    countByte,
    selectionLocalStorage,
  ],
  data() {
    return {
      isGenerateStoreSelectModalShow: false,
      isCollectLicenceSelectModalShow: false,
      isDisposalLicenceSelectModalShow: false,
      isCollectCommonLicenceSelectModalShow: false,
      isDisposalCommonLicenceSelectModalShow: false,
      isConfirmModalShow: false,
      gotoRoute: false,
      errMsgs: [],
      wasteType: DOMAIN.WASTE_TYPE,
      manifestType: DOMAIN.MANIFEST_TYPE,
      postData: null,
      destinationType: DOMAIN.DESTINATION_TYPE,
      destinationTypeOpts: [
        { id: DOMAIN.DESTINATION_TYPE.TRANSSHIPMENT, name: "積替保管" },
        { id: DOMAIN.DESTINATION_TYPE.DISPOSAL_SITE, name: "処分場" },
      ],
      isRemoveWasteInfo: false,
      isRemoveCollectSection: false,
      processing: false,

      // 202202 CS 委託契約
      /**
       * 最終処分場モーダル開閉状態
       */
      isFinalDisposalSiteSelectListModalShow: false,
      autoSelect: false,
      selectTemplate: SelectItemTemplate,
    };
  },

  computed:{
    disposalSiteOptsChangeValue() {
      return this.disposalSiteOpts
    }
  },
  watch:{
    disposalSiteOptsChangeValue: function(){
      // 運搬先の配列情報が変化されたら、最終処理場の選択設定情報と処分場の選択情報をリセットする
      this.form.entrustDisposalCompanyInfo.finalDisposalSiteList = [];
      this.form.entrustDisposalCompanyInfo.disposalSiteId = null;
    },
    selectedCollectCompany: function(newVal) {
      newVal.forEach((e, i) => {
        const isNotChange = e.id === this.form.entrustCollectCompanyInfo[i].collectCompanyId
        this.form.entrustCollectCompanyInfo[i].collectCompanyId = e ? e.id : null
        if (isNotChange) return;
        this.changeCollectCompanyIdHandler(this.form.entrustCollectCompanyInfo[i])
      })
    },
    selectedDisposalCompany: function (newVal) {
      this.form.entrustDisposalCompanyInfo.disposalCompanyId = newVal ? newVal.id : null
      this.changeDisposalCompanyIdHandler()
    }
  },
  methods: {
    onChange(queryString, ref) {
      this.$nextTick(() => {
        this.$refs[ref].searchText = queryString;
      });
    },
    updateCollectCompany (entrustCollectIndex, text) {
      if (!text || text.length === 0) {
        //全ての運搬事業者の選択肢を表示する
        this.selectCollectCompanyList[entrustCollectIndex] = this.collectCompanyList;
        this.$forceUpdate();
        return
      }
      this.selectCollectCompanyList[entrustCollectIndex] = this.collectCompanyList.filter((item) => {
        return (new RegExp(text.toLowerCase())).test(item.name.toLowerCase())
      })
      this.$forceUpdate();
    },

    updateDisposalCompany (text) {
      if (!text || text.length === 0) {
        //全ての運搬事業者の選択肢を表示する
        this.selectDisposalCompanyList = this.disposalCompanyList;
        return
      }
      this.selectDisposalCompanyList = this.disposalCompanyList.filter((item) => {
        return (new RegExp(text.toLowerCase())).test(item.name.toLowerCase())
      })
    },
    getLabel (item) {
      if (item) {
        return item.name
      }
      return ''
    },
    // 廃棄物情報を複数登録した場合に実行
    syncWasteTypeId() {

      this.errMsgs = [];
      // 産業廃棄物に変更すると、処分事業場をリセット
      if (this.form.entrustWasteList[0].wasteTypeId === this.wasteType.INDUSTRIAL_WASTE) {
        this.disposalSiteOpts = [];
        this.form.entrustDisposalCompanyInfo.disposalCompanyId = null;
      } else {
        // 有価・一般に変更すると
        if (this.form.entrustCollectCompanyInfo.length > 1) {
          // 廃棄物区分を産業廃棄物から有価、一般廃棄物に変更時に廃棄物情報のサイズは２以上ならエラーとする
          this.errMsgs.push("有価、一般廃棄物を指定する場合は、運搬区間の複数指定はできません。");
          window.scrollTo({ top: 0, behavior: "smooth" });
          return;
        }
        // 既に設定した許可証をリセット
        this.form.entrustCollectCompanyInfo.forEach(ecc => {
          ecc.collectLicenceInfo = [];
          ecc.commonWasteCollectLicenceInfo = [];
          ecc.transshipmentStorageId = null;
        });
        // 許可証情報が存在していた場合、処分事業場をリセット
        if(this.form.entrustDisposalCompanyInfo.commonWasteDisposalLicenceInfo.length > 0) {
          this.disposalSiteOpts = [];
          this.form.entrustDisposalCompanyInfo.disposalCompanyId = null;
        }
        this.form.entrustDisposalCompanyInfo.disposalLicenceInfo = [];
        this.form.entrustDisposalCompanyInfo.commonWasteDisposalLicenceInfo = [];
      }
      if (this.form.entrustWasteList.length < 2) return;
      const wasteTypeId = this.form.entrustWasteList[0].wasteTypeId;
      this.form.entrustWasteList.forEach(waste => waste.wasteTypeId = wasteTypeId);
    },

    // post用にデータをフォーマット
    formatPostData() {
      return new Promise((resolve) => {
        const newData = JSON.parse(JSON.stringify(this.form)); // deep copy

        // 排出事業者情報
        newData.generateStoreList = newData.generateStoreList.map(s => {
            return {
              generateStoreId: s.id
            };
          });
        // 廃棄物情報
        newData.entrustWasteList.forEach(ew => {
          delete ew.cbaItemOpts;
          delete ew.wasteNameOpts;
          // 廃棄物情報-処分方法
          if (ew.disposalWayType[0].disposalWayTypeId === null) {
            ew.disposalWayType = null
          }
        });
        // 運搬事業者情報
        newData.entrustCollectCompanyInfo.forEach(ec => {
          delete ec.companyOpts;
          delete ec.transShipmentOpts;
          if (newData.entrustWasteList[0].wasteTypeId === this.wasteType.COMMON_WASTE
              || ec.collectLicenceInfo.length === 0 || ec.collectLicenceInfo[0].collectLicenceId === null) {
            ec.collectLicenceInfo = null;
          }
          if (newData.entrustWasteList[0].wasteTypeId === this.wasteType.INDUSTRIAL_WASTE
              || ec.commonWasteCollectLicenceInfo.length === 0 || ec.commonWasteCollectLicenceInfo[0].commonWasteCollectLicenceId === null) {
            ec.commonWasteCollectLicenceInfo = null;
          }
          if (ec.entrustCollectContractFileList.length === 0 || ec.entrustCollectContractFileList[0].contractFileName === null) {
            ec.entrustCollectContractFileList = null;
          }
          // アップロードしない場合、初期値の要素を削除
          if ((ec.entrustCollectUnitPriceList && ec.entrustCollectUnitPriceList.length > 0 && ec.entrustCollectUnitPriceList[0].generateStoreName === "") || ec.entrustCollectUnitPriceList === null) {
            ec.entrustCollectUnitPriceList = [];
          }
        });
        // 処分事業者情報
        if (newData.entrustWasteList[0].wasteTypeId === this.wasteType.COMMON_WASTE
            || newData.entrustDisposalCompanyInfo.disposalLicenceInfo.length === 0 || newData.entrustDisposalCompanyInfo.disposalLicenceInfo[0].disposalLicenceId === null) {
          newData.entrustDisposalCompanyInfo.disposalLicenceInfo = null;
        }
        if (newData.entrustWasteList[0].wasteTypeId === this.wasteType.INDUSTRIAL_WASTE
            || newData.entrustDisposalCompanyInfo.commonWasteDisposalLicenceInfo.length === 0 || newData.entrustDisposalCompanyInfo.commonWasteDisposalLicenceInfo[0].commonWasteDisposalLicenceId === null) {
          newData.entrustDisposalCompanyInfo.commonWasteDisposalLicenceInfo = null;
        }
        if (newData.entrustDisposalCompanyInfo.entrustDisposalContractFileList.length === 0 || newData.entrustDisposalCompanyInfo.entrustDisposalContractFileList[0].contractFileName === null) {
          newData.entrustDisposalCompanyInfo.entrustDisposalContractFileList = null
        }
        // 最終処分場情報
        newData.entrustDisposalCompanyInfo.finalDisposalSiteList =
          newData.entrustDisposalCompanyInfo.finalDisposalSiteList.map((m) => {
            return {
              finalDisposalSiteId: m.finalDisposalSiteId,
            };
          });
        // 処分単価情報: アップロードしない場合、初期値の要素を削除
        if ((newData.entrustDisposalCompanyInfo.entrustDisposalUnitPriceList && newData.entrustDisposalCompanyInfo.entrustDisposalUnitPriceList.length > 0 && newData.entrustDisposalCompanyInfo.entrustDisposalUnitPriceList[0].generateStoreName === "") || newData.entrustDisposalCompanyInfo.entrustDisposalUnitPriceList === null) {
          newData.entrustDisposalCompanyInfo.entrustDisposalUnitPriceList = [];
        }

        resolve(newData);
      });
    },

    formHandler(gotoRoute) {
      this.errMsgs = [];

      // 廃棄物情報が１つもない状態または運搬区間情報が１つもない状態はエラーとする
      if(this.form.entrustWasteList.length < 1 || this.form.entrustCollectCompanyInfo.length < 1) {
        if (this.form.entrustWasteList.length < 1) {
          this.errMsgs.push("廃棄物情報を登録してください。");
        }
        if (this.form.entrustCollectCompanyInfo.length < 1) {
          this.errMsgs.push("運搬委託契約先情報を登録してください。");
        }
        this.processing = false;
        window.scrollTo({ top: 0, behavior: "smooth" });
        return;
      }

      if (!this.runValidate()) {
        this.processing = false;
        this.errMsgs.push("入力に誤りがあります。");
        window.scrollTo({ top: 0, behavior: "smooth" });
        return;
      }

      if (this.csvCollectErrorMsgs.filter( e => e.length > 0).length > 0 || this.csvDisposalErrorMsgs.length > 0) {
        this.processing = false;
        this.errMsgs.push("入力に誤りがあります。");
        window.scrollTo({ top: 0, behavior: "smooth" });
        return;
      }

      // 委託契約書突合APIの実行
      this.formatPostData().then((res) => {
        this.postData = res;
        // 廃棄物区分は産業廃棄物の場合、突合チェックを必須とする
        if (this.form.entrustWasteList[0].wasteTypeId === this.wasteType.INDUSTRIAL_WASTE) {
          axios.post(API_URL.ENTRUST.MATCHING, this.postData)
            .then(() => {
              this.isConfirmModalShow = true;
              this.gotoRoute = gotoRoute;
            })
            .catch((err) => {
              this.errMsgs.push(err.response && err.response.data ? err.response.data.message : err.message);
              this.isConfirmModalShow = false;
              window.scrollTo({ top: 0, behavior: "smooth" });
            });
        } else {
          this.isConfirmModalShow = true;
          this.gotoRoute = gotoRoute;
        }
      });
    },

    submit() {
      this.postEntrust();
    },

    postEntrust() {
      // 任意送付先メール情報のレスポンスへ変換
      this.postData.entrustDeliveryConfirmation.anyMailList.forEach((mail, index) => {
        if (index == 0 && mail != null) {
          this.postData.entrustDeliveryConfirmation.anyMail1 = mail;
        }
        if (index == 1 && mail != null) {
          this.postData.entrustDeliveryConfirmation.anyMail2 = mail;
        }
        if (index == 2 && mail != null) {
          this.postData.entrustDeliveryConfirmation.anyMail3 = mail;
        }
        if (index == 3 && mail != null) {
          this.postData.entrustDeliveryConfirmation.anyMail4 = mail;
        }
        if (index == 4 && mail != null) {
          this.postData.entrustDeliveryConfirmation.anyMail5 = mail;
        }
      })
      // 登録担当者名と引き渡し担当者名を全角に変換
      this.postData.registMemberName = this.toFullWidthString(this.removeWhitespaceIfOnlyWhitespace(this.postData.registMemberName));
      this.postData.generateMemberName = this.toFullWidthString(this.removeWhitespaceIfOnlyWhitespace(this.postData.generateMemberName));

      this.processing = true; // 2重submit防止
      axios
        .post(API_URL.ENTRUST.CREATE, this.postData)
        .then((res) => {
          this.$cookies.set(COOKIE_INFO.MESSAGE, "委託契約書を登録しました。");
          if (this.gotoRoute) {
            this.$router.push(`/route?entrustNo=${res.data.entrustId}`);
          } else {
            this.$router.push("/entrust");
          }
        })
        .catch((err) => {
          this.errMsgs.push(err.response && err.response.data ? err.response.data.message : err.message);
          this.processing = false; // 2重submit解除
          this.isConfirmModalShow = false;
          window.scrollTo({ top: 0, behavior: "smooth" });
        });
    },
    removeContractFile(collectIndex, fileIndex) {
      const removedFileList = this.form.entrustCollectCompanyInfo[collectIndex].entrustCollectContractFileList.filter((v, i) => {
        return i !== fileIndex
      });

      this.form.entrustCollectCompanyInfo[collectIndex].entrustCollectContractFileList = removedFileList;

    },
    removeDisposalContractFile(fileIndex) {
      const removedFileList = this.form.entrustDisposalCompanyInfo.entrustDisposalContractFileList.filter((v, i) => {
        return i !== fileIndex
      });

      this.form.entrustDisposalCompanyInfo.entrustDisposalContractFileList = removedFileList;

    },
    changeDisposalSite() {
      // 運搬先の選択内容変更が検知されたら、最終処理場の選択設定情報をリセットする
      this.form.entrustDisposalCompanyInfo.finalDisposalSiteList = [];
    },
  },

  mounted() {
    this.getGeneratorChoiceApi();
    this.getWasteTypeApi();
    this.getManifestTypeApi();
    this.getJwnetItemsApi();
    this.getConstitutionItemApi();
    this.getWasteUnitApi();
    this.getTermTypeApi();
    this.getShapeApi();
    this.getDisposalWayTypesApi();
    this.getDisposalCompanyListApi();
    this.getCollectCompanyListApi();
    this.getCollectCollectWayTypeApi();
  },
};
</script>

<style scoped lang="scss">
.sub-title {
  border-bottom: 1px solid #cbd0d8;
  margin: 2rem 0;
  font-size: 1.6rem;
}

.c-disposalSiteList {
  .stretch {
    flex: 1;
  }

  .c-infoList__row {
    display: flex;
  }

  &__item {
    + .c-disposalSiteList__item {
      border-top: 1px solid #ccd0d7;
      padding-top: 5px;
    }

    .c-inputWrap + & {
      margin-top: 15px;
    }

    display: flex;
    margin-top: 10px;

    span {
      + span {
        margin-left: 1em;
      }
      &.name {
        width: 10em;
      }
      &.address {
        width: 25em;
      }
      &.code {
        width: 13em;
      }
    }

  }
}

.c-inline{
  display: inline;
}

.c-inline-block{
  display: inline-block;
}
</style>
