import axios from "axios";
import {API_URL} from "@/const";

export default {
  data() {
    return {
      generateCompanyList: [],
      wasteTypeList: [],
      manifestTypeList: [],
      jwnetItemsOpt: [],
      constitutionItemList: [],
      wasteUnitOpt: [],
      termTypes: [],
      shapeOpt: [],
      disposalWayTypes: [],
      disposalCompanyList: [],
      collectCompanyList: [],
      collectWayTypeList: [],
      // 202202 CS 委託契約
      /**
       * 最終処分場選択肢情報
       */
      finalDisposalSiteSelectList: [],
    };
  },

  methods: {
    // CBA排出事業者選択肢取得API  GENERATOR_CHOICE
    getGeneratorChoiceApi() {
      return axios
        .get(API_URL.ENTRUST.CBA_GENERATOR_COMPANY)
        .then((res) => {
          this.generateCompanyList = res.data.generateCompanyList;
        })
        .catch((err) => {
          console.log(err);
        });
    },

    // 廃棄物区分選択肢取得API  DOMAIN.WASTE_TYPE
    getWasteTypeApi() {
      return axios
        .get(API_URL.DOMAIN.WASTE_TYPE)
        .then((res) => {
          this.wasteTypeList = res.data.wasteTypeList;
          this.form.entrustWasteList[0].wasteTypeId = this.wasteTypeList[0].id;
        })
        .catch((err) => {
          console.log(err);
        });
    },

    // マニフェスト区分選択肢取得API  DOMAIN.MANIFEST_TYPE
    getManifestTypeApi() {
      return axios
        .get(API_URL.DOMAIN.MANIFEST_TYPE)
        .then((res) => {
          this.manifestTypeList = res.data.manifestTypeList;
          this.form.entrustWasteList[0].manifestTypeId =
            this.manifestTypeList[0].id;
        })
        .catch((err) => {
          console.log(err);
        });
    },

    // 廃棄物種類選択肢取得API   DOMAIN.JWNET_ITEM_TYPE
    getJwnetItemsApi() {
      return axios
        .get(API_URL.DOMAIN.JWNET_ITEM_TYPE)
        .then((res) => {
          this.jwnetItemsOpt = res.data.jwnetItems;
        })
        .catch((err) => {
          console.log(err);
        });
    },

    // 構成種類選択肢取得API MANIFEST.CONSTITUTION_ITEM
    getConstitutionItemApi() {
      return axios
        .get(API_URL.MANIFEST.CONSTITUTION_ITEM)
        .then((res) => {
          this.constitutionItemList = res.data.wasteItems;
        })
        .catch((err) => {
          console.log(err);
        });
    },

    // 廃棄物数量単位選択肢取得API    API_URL.DOMAIN.WASTE_UNIT
    getWasteUnitApi() {
      axios
        .get(API_URL.DOMAIN.WASTE_UNIT)
        .then((res) => {
          this.wasteUnitOpt = res.data.wasteUnitTypes;
        })
        .catch((err) => {
          console.log(err);
        });
    },

    // 期間選択肢取得API    API_URL.DOMAIN.TERM_TYPE
    getTermTypeApi() {
      axios
        .get(API_URL.DOMAIN.TERM_TYPE)
        .then((res) => {
          this.termTypes = res.data.termTypes;
        })
        .catch((err) => {
          console.log(err);
        });
    },

    // 荷姿選択肢取得API    API_URL.DOMAIN.SHAPE
    getShapeApi() {
      axios
        .get(API_URL.DOMAIN.SHAPE)
        .then((res) => {
          this.shapeOpt = res.data.shapeTypes;
        })
        .catch((err) => {
          console.log(err);
        });
    },

    // 処分方法選択肢取得API   DOMAIN.DISPOSAL_WAY_TYPE
    getDisposalWayTypesApi() {
      return axios
        .get(API_URL.DOMAIN.DISPOSAL_WAY_TYPE)
        .then((res) => {
          this.disposalWayTypes = res.data.disposalWayTypeList;
        })
        .catch((err) => {
          console.log(err);
        });
    },

    // CBA処分事業者選択肢取得API ENTRUST.DISPOSAL_COMPANY
    getDisposalCompanyListApi() {
      this.getDisposalCompanySelectionForEntrustCreate().then((data) => {
        this.disposalCompanyList = data;
        this.selectDisposalCompanyList = data;
        if (this.form.entrustDisposalCompanyInfo.disposalCompanyId) {
          this.selectedDisposalCompany = this.disposalCompanyList.find(e => e.id === this.form.entrustDisposalCompanyInfo.disposalCompanyId)
        }
      }).catch(() => {})
    },

    // CBA処分事業者選択肢取得API ENTRUST.COLLECT_COMPANY
    getCollectCompanyListApi() {
      this.getCollectCompanySelectionForEntrustCreate().then((data) => {
        this.collectCompanyList = data;
        // 1つ目の運搬事業者のsuggestionの初期値を設定
        this.selectCollectCompanyList[0] = data;
        this.selectedCollectCompany[0] = null;
      }).catch(() => {})
    },

    // 運搬方法選択肢取得API DOMAIN.COLLECT_WAY_TYPE
    getCollectCollectWayTypeApi() {
      return axios
        .get(API_URL.DOMAIN.COLLECT_WAY_TYPE)
        .then((res) => {
          this.collectWayTypeList = res.data.collectWayTypeList;
        })
        .catch((err) => {
          console.log(err);
        });
    },

    // 202202 CS 委託契約
    /**
     * 最終処分場選択肢取得API
     */
    getFinalDisposalSiteSelectListApi(disposalSiteId) {
      return axios
        .get(`${API_URL.ENTRUST.SELECT_FINAL_DISPOSAL_SITE}/${disposalSiteId}`)
        .then((res) => {
          this.finalDisposalSiteSelectList = res.data.finalDisposalSiteSelectList;
        })
        .catch((err) => {
          console.log(err);
        });
    },
  },
};
