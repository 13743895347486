import axios from "axios";
import { API_URL, DOMAIN } from "@/const";

export default {
  data() {
    return {
      generateFactoryIdList: [],
      disposalSiteOpts: [],
      wasteType: DOMAIN.WASTE_TYPE,
      disposalSiteByCompanyIdOpts: [], // POSTしない
    };
  },

  methods: {
    // 処分事業者編集時
    changeDisposalCompanyIdHandler() {
      this.form.entrustDisposalCompanyInfo.disposalLicenceInfo = []; // 処分許可証のリセット
      this.form.entrustDisposalCompanyInfo.disposalSiteId = null; // 処分場のリセット
      this.disposalSiteOpts = []; // 処分場プルダウンのリセット
      this.getDisposalSiteByCompanyIdApi();
    },
 
    // 処分事業者IDで処分場選択肢取得API ENTRUST.DISPOSAL_SITE_BY_COMPANYID + 処分事業者ID
    getDisposalSiteByCompanyIdApi() {
      axios
        .get(API_URL.ENTRUST.DISPOSAL_SITE_BY_COMPANYID + this.form.entrustDisposalCompanyInfo.disposalCompanyId)
        .then((res) => {
          this.disposalSiteOpts = res.data.disposalSiteIdList;
          this.disposalSiteByCompanyIdOpts = res.data.disposalSiteIdList;
          if (!this.disposalSiteOpts.filter(d => d.id === this.form.entrustDisposalCompanyInfo.disposalSiteId).length) {
            this.form.entrustDisposalCompanyInfo.disposalSiteId = null; // 処分場のリセット
          }
        })
        .catch((err) => {
          this.disposalSiteOpts = [];
          console.log(err);
        });
    },

    // 処分許可証番号設定モーダル内で設定押下
    setDisposalLicence(licences) {
      this.form.entrustDisposalCompanyInfo.disposalLicenceInfo = licences.map(
        (licence) => {
          return {
            disposalLicenceId: licence.id,
            name: licence.licenceNo,
          };
        }
      );
      this.getDisposalSiteApi(licences.map(l => l.id));
    },

    // 一般廃棄物処分許可証番号設定モーダル内で設定押下
    setDisposalCommonLicence(licences) {
      this.form.entrustDisposalCompanyInfo.commonWasteDisposalLicenceInfo = licences.map(
        (licence) => {
          return {
            commonWasteDisposalLicenceId: licence.id,
            name: licence.licenceNo,
          };
        }
      );
    },

    // 許可証別処分場選択肢取得API ENTRUST.TRANSSHIPMENT_BY_LICENCE + ？collectLicenceId＝{運搬許可証ID}
    getDisposalSiteApi(licenceIdList) {
      // 許可書が未設定の場合、運搬先選択肢の表示は処分事業者に紐づく処分場情報で表示する
      if (licenceIdList.length === 0) {
        this.disposalSiteOpts = this.disposalSiteByCompanyIdOpts;
        this.form.entrustDisposalCompanyInfo.disposalSiteId = null; // 処分場のリセット
        return;
      }
      axios
        .get(API_URL.ENTRUST.DISPOSAL_SITE_BY_LICENCE + `?licenceIdList=${licenceIdList.join(",")}`)
        .then((res) => {
          this.disposalSiteOpts = res.data.disposalSiteSelectList;
          if (!this.disposalSiteOpts.filter(d => d.id === this.form.entrustDisposalCompanyInfo.disposalSiteId).length) {
            this.form.entrustDisposalCompanyInfo.disposalSiteId = null; // 処分場のリセット
          }
        })
        .catch((err) => {
          this.disposalSiteOpts = [];
          console.log(err);
        });
    },
  },
};
