export default {
  data() {
    return {
      tempVal: {
        // postしない
        generateCompany: null, // 排出事業者
        generateFactory: [], // 排出事業所
      },
      selectCollectCompanyList: [[]], // postしない。運搬事業者選択肢
      selectDisposalCompanyList: [], // postしない。処分事業者選択肢
      selectedCollectCompany: [[]],
      selectedDisposalCompany: null,

      form: {
        activeStartDate: null, // 契約開始日
        activeEndDate: null, // 契約終了日
        isUpdate: false, // 更新中
        generateStoreList: [
          {
            generateStoreId: 0, // 排出事業場ID
          },
        ],
        registMemberName: null,
        generateMemberName: null,
        entrustDeliveryConfirmation: {
          isDeliveryConfirmSendFlag: false,
          generateDeliveryConfirmMail: null,
          collectDeliveryConfirmMail: null,
          disposalDeliveryConfirmMail: null,
          anyMailList: [null], // POSTなし
          anyMail1: null,
          anyMail2: null,
          anyMail3: null,
          anyMail4: null,
          anyMail5: null,
          qrInfo1: null,
          qrInfo2: null,
          qrInfo3: null,
          qrInfo4: null,
          qrInfo5: null,
        },
        // 廃棄物情報
        entrustWasteList: [
          {
            wasteTypeId: null,
            manifestTypeId: null,
            jwnetItemTypeId: null,
            constitutionItemIdList: [
              {
                constitutionItemId: null,
              },
            ],
            cbaItemId: null,
            wasteNameId: null,
            plansQuantity: null,
            plansWasteUnitTypeId: null,
            plansWasteTermTypeId: null,
            limitQuantity: null,
            limitWasteUnitTypeId: null,
            limitWasteTermTypeId: null,
            shapeTypeIdList: [
              {
                shapeTypeId: null,
              },
            ],
            disposalWayType: [
              {
                disposalWayTypeId: null,
              },
            ],
            processInfo: "",
            wdsFileName: null,
            cbaItemOpts: [], // POSTしない。POST前に削除する
            wasteNameOpts: [], // POSTしない。POST前に削除する
          },
        ],
        entrustCollectCompanyInfo: [
          {
            sectionNo: 1,
            collectCompanyId: null,
            contractStartDate: "",
            contractEndDate: "",
            isAutoUpdate: true,
            remarks: "",
            entrustCollectContractFileList: [],
            collectFactoryId: null,
            destinationTypeId: 2,
            collectWayTypeId: null,
            transshipmentStorageId: null,
            collectLicenceInfo: [
              {
                collectLicenceId: null,
              },
            ],
            commonWasteCollectLicenceInfo: [
              {
                commonWasteCollectLicenceId: null,
              },
            ],
            entrustCollectUnitPriceList: [
              {
                generateStoreName: "",
                jwnetItemTypeName: "",
                unitPrice: 0,
                wasteUnitTypeName: ""
              }
            ],
            companyOpts: [], // POSTしない。POST前に削除する
            transShipmentOpts: [], // POSTしない。POST前に削除する
            collectUnitPriceFileName: null, // POSTしない
          },
        ],
        entrustDisposalCompanyInfo: {
          disposalCompanyId: null,
          contractStartDate: "",
          contractEndDate: "",
          isAutoUpdate: true,
          remarks: "",
          entrustDisposalContractFileList: [],
          disposalSiteId: null,
          disposalLicenceInfo: [
            {
              disposalLicenceId: null,
            },
          ],
          commonWasteDisposalLicenceInfo: [
            {
              commonWasteDisposalLicenceId: null,
            },
          ],
          entrustDisposalUnitPriceList: [
            {
              generateStoreName: "",
              jwnetItemTypeName: "",
              unitPrice: 0,
              wasteUnitTypeName: ""
            }
          ],
          disposalUnitPriceFileName: null, // POSTしない
          finalDisposalSiteList: [],
        },
      },
    };
  },
};
