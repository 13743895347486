import axios from "axios";
import { API_URL, DOMAIN } from "@/const";

export default {
  data() {
    return {
      targetEntrustCollect: null,
      targetSelectedLicense: []
    };
  },

  methods: {
    // かたまりを追加
    addCollectInfoHandler() {
      // 区間 > 運搬先を積替保管に変更
      this.form.entrustCollectCompanyInfo.forEach((cc) => {
        cc.destinationTypeId = DOMAIN.DESTINATION_TYPE.TRANSSHIPMENT;
      });

      const newList = {
        sectionNo: null,
        collectCompanyId: null,
        contractStartDate: "",
        contractEndDate: "",
        isAutoUpdate: true,
        remarks: "",
        entrustCollectContractFileList: [],
        collectFactoryId: null,
        destinationTypeId: DOMAIN.DESTINATION_TYPE.DISPOSAL_SITE,
        collectWayTypeId: null,
        transshipmentStorageId: null,
        collectLicenceInfo: [],
        commonCollectLicenceInfo: [],
        companyOpts: [],
        transShipmentOpts: [],
        entrustCollectUnitPriceList: [],
        collectUnitPriceFileName: null, // POSTしないs
      };

      const newValidate = {
        collectCompanyId: { isValid: true, errMsg: "" },
        contractStartDate: { isValid: true, errMsg: "" },
        contractEndDate: { isValid: true, errMsg: "" },
        collectFactoryId: { isValid: true, errMsg: "" },
        collectWayTypeId: { isValid: true, errMsg: "" },
        transshipmentStorageId: { isValid: true, errMsg: "" },
      };

      this.form.entrustCollectCompanyInfo = [
        ...this.form.entrustCollectCompanyInfo,
        newList,
      ];
      this.validate.entrustCollectCompanyInfo = [
        ...this.validate.entrustCollectCompanyInfo,
        newValidate,
      ];

      // 該当する運搬事業者のsuggestionを追加
      const newItems = this.collectCompanyList
      this.selectCollectCompanyList = [...this.selectCollectCompanyList, newItems]
      this.selectedCollectCompany = [...this.selectedCollectCompany, null]
      this.applySectionNo();
    },

    // 運搬事業者を削除する時、各区間のdestinationTypeIdを再設定
    resetDestinationTypeForSection() {
      if (!this.form.entrustCollectCompanyInfo) return;
        this.form.entrustCollectCompanyInfo.forEach( (eci, index) => {
          if (index == (this.form.entrustCollectCompanyInfo.length - 1)) {
            eci.destinationTypeId = this.destinationType.DISPOSAL_SITE;
          } else {
            eci.destinationTypeId = this.destinationType.TRANSSHIPMENT;
          }
        }
      )
    },

    // かたまりを削除
    removeCollectInfoHandler(index) {
      this.errMsgs = [];

      this.form.entrustCollectCompanyInfo = this.form.entrustCollectCompanyInfo.filter((v, i) => i !== index);

      this.validate.entrustCollectCompanyInfo =
      this.validate.entrustCollectCompanyInfo.filter((v, i) => i !== index);

      // 現在の廃棄物区分は産業廃棄物以外場合、設定した許可証をリセット
      if (this.form.entrustWasteList[0].wasteTypeId !== this.wasteType.INDUSTRIAL_WASTE) {
        this.form.entrustCollectCompanyInfo.forEach(ecc => {
          ecc.collectLicenceInfo = [];
          ecc.transshipmentStorageId = null;
        });
        this.form.entrustDisposalCompanyInfo.disposalLicenceInfo = [];
      }

      this.isRemoveCollectSection = true;
      this.applySectionNo();
      this.reloadTranshipment();

      // 運搬事業者を削除する時、各区間のdestinationTypeIdを再設定
      this.resetDestinationTypeForSection();

      // 該当する運搬事業者のsuggestionを削除
      this.selectCollectCompanyList = this.selectCollectCompanyList.filter((v, i) => i !== index);
      this.selectedCollectCompany = this.selectedCollectCompany.filter((v, i) => i !== index);
    },

    // 運搬事業者変更時
    changeCollectCompanyIdHandler(entrustCollect) {
      if (!this.isRemoveCollectSection) entrustCollect.collectLicenceInfo = []; // 運搬許可証のリセット
      if (!this.isRemoveCollectSection) entrustCollect.collectFactoryId = null; // 運搬事業所のリセット
      if (!this.isRemoveCollectSection)
        entrustCollect.transshipmentStorageId = null; // 積替保管のリセット
      if (!this.isRemoveCollectSection) entrustCollect.transShipmentOpts = []; // 積替保管のプルダウンリセット
      this.isRemoveCollectSection = false;

      // 運搬事業者の取得
      return axios
        .get(
          API_URL.ENTRUST.COLLECT_SELECT_FACTORY +
            entrustCollect.collectCompanyId
        )
        .then((res) => {
          entrustCollect.companyOpts = res.data.generateFactoryIdList;
          this.reloadTranshipment();
        })
        .catch((err) => {
          console.log(err);
        });
    },

    // 区間を連番にする
    applySectionNo() {
      this.form.entrustCollectCompanyInfo.map((collectCompany, index) => {
        collectCompany.sectionNo = index + 1;
      });
    },

    // 許可証選択モーダル展開
    clickShowCollectLicenceModalHandler(entrustCollect) {
      this.targetEntrustCollect = entrustCollect;
      this.targetSelectedLicense = entrustCollect.collectLicenceInfo.map(c => { return { id: c.collectLicenceId } })
      this.isCollectLicenceSelectModalShow = true;
    },

    // 一般許可証選択モーダル展開
    clickShowCollectCommonLicenceModalHandler(entrustCollect) {
      this.targetEntrustCollect = entrustCollect;
      this.isCollectCommonLicenceSelectModalShow = true;
    },

    // 運搬許可証選択モーダル内で設定押下
    setCollectLicence(payload) {
      payload.target.collectLicenceInfo = payload.licences.map((licence) => {
        return {
          collectLicenceId: licence.id,
          name: licence.licenceNo,
        };
      });
      if(payload.target.collectLicenceInfo.length === 0) {
        payload.target.transshipmentStorageId = null; // 積替保管のリセット
      }

      if (payload.target.collectLicenceInfo.length) {
        this.getTransShipmentApi(payload.target).then(() => {
          this.reloadTranshipment();
        });
      }
    },

    // 一般廃棄物運搬許可証選択モーダル内で設定押下
    setCollectCommonLicence(payload) {
      payload.target.commonWasteCollectLicenceInfo = payload.licences.map((licence) => {
        return {
          commonWasteCollectLicenceId: licence.id,
          name: licence.licenceNo,
        };
      });
    },

    // 運搬区間が複数ある場合、荷下ろし場所の選択肢として、積替保管場所選択肢取得APIを呼ぶ
    reloadTranshipment() {
      for(let i = 0; i< this.form.entrustCollectCompanyInfo.length; i++) {
        const collectLicenceInfoList = [];
        const currentEntrustCollectCompanyInfo = this.form.entrustCollectCompanyInfo[i]
        currentEntrustCollectCompanyInfo.collectLicenceInfo.forEach(c => collectLicenceInfoList.push(c));
        if(i+1 < this.form.entrustCollectCompanyInfo.length) {
          this.form.entrustCollectCompanyInfo[i+1].collectLicenceInfo.forEach(c => collectLicenceInfoList.push(c));
        }
        axios
        .get(
          API_URL.ENTRUST.TRANSSHIPMENT_BY_LICENCE +
            `?licenceIdList=${collectLicenceInfoList
              .map((l) => l.collectLicenceId)
              .join(",")}`
        )
        .then((res) => {
          currentEntrustCollectCompanyInfo.transShipmentOpts = res.data.transshipmentSelectList;
          if (
            !currentEntrustCollectCompanyInfo.transShipmentOpts.filter(
              (t) => t.id === currentEntrustCollectCompanyInfo.transshipmentStorageId
            ).length
          ) {
            currentEntrustCollectCompanyInfo.transshipmentStorageId = null; // 積替保管のリセット
          }
        })
        .catch((err) => {
          currentEntrustCollectCompanyInfo.transShipmentOpts = [];
          console.log(err);
        });
      }
    },

    // 積替保管場所選択肢取得API ENTRUST.TRANSSHIPMENT_BY_LICENCE + ？collectLicenceId＝{運搬許可証ID}
    getTransShipmentApi(target) {
      return axios
        .get(
          API_URL.ENTRUST.TRANSSHIPMENT_BY_LICENCE +
            `?licenceIdList=${target.collectLicenceInfo
              .map((l) => l.collectLicenceId)
              .join(",")}`
        )
        .then((res) => {
          target.transShipmentOpts = res.data.transshipmentSelectList;
          if (
            !target.transShipmentOpts.filter(
              (t) => t.id === target.transshipmentStorageId
            ).length
          ) {
            target.transshipmentStorageId = null; // 積替保管のリセット
          }
        })
        .catch((err) => {
          target.transShipmentOpts = [];
          console.log(err);
        });
    },
  },

  watch: {
    isCollectLicenceSelectModalShow() {
      if (!this.isCollectLicenceSelectModalShow) {
        this.targetEntrustCollect = null;
        this.targetSelectedLicense = []
      }
    },
  },
};
