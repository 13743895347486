<template>
  <div v-if="isShow" class="c-modal lg">
    <div class="c-modal__overlay"></div>
    <div class="c-modal__content">
      <div class="c-modal__head">
        <h5 class="c-lead-s">排出事業場設定</h5>
        <span class="c-modal__head__close" @click="closeModalHandler"></span>
      </div>
      <div class="c-modal__body">
        <div class="c-inputWrap l-area">
          <div class="c-inputWrap__label">
            <label>事業者名</label>
          </div>
          <div class="c-inputWrap__items">
            <Selectbox
              :value.sync="selectedFactory"
              :validation="validateJustBlank"
            >
              <option
                v-for="gf in generateFactory"
                :key="'gf-' + gf.id"
                :value="gf"
              >
                {{ gf.name }}
              </option>
            </Selectbox>
          </div>
        </div>
        <div class="c-modal__body__originalList" v-if="generateStoreList.length">
          <div
              class="c-checkbox"
              v-for="store in generateStoreList"
              :key="store.id"
          >
            <input
                :id="'store-sort-cb-' + store.id"
                type="checkbox"
                :value="store"
                v-model="selectedStores"
            />
            <label
                class="c-checkbox__label"
                :for="'store-sort-cb-' + store.id"
            >
              <span class="c-checkbox__box"></span>
              {{ dottedText(store.name, 23) }}
            </label>
          </div>
        </div>
      </div>
      <div class="c-modal__foot">
        <div class="c-btn primary small" @click="clickSetHandler">設定</div>
      </div>
    </div>
  </div>
</template>

<script>
import axios from "axios";
import Selectbox from "@/components/parts/input/Selectbox";
import common from "@/mixin/common";
import validation from "@/mixin/validation";
import {API_URL} from "@/const";

export default {
  props: {
    isShow: {
      type: Boolean,
    },
    generateFactory: {
      type: Array,
    },
  },
  components: {
    Selectbox,
  },
  mixins: [common, validation],
  data() {
    return {
      generateStoreList: [],
      selectedFactory: {},
      selectedStores: [],
    };
  },

  methods: {
    closeModalHandler() {
      this.$emit("update:isShow", false);
      this.selectedStores = [];
    },

    clickSetHandler() {
      this.$emit("setSelectedStores", this.selectedStores);
      this.$emit("update:isShow", false);
    },

    // API  API_URL.GENERATOR.FACTORY_SELECT + ?generateFactoryId={排出事業所ID}
    getGenerateStoreListApi() {
      axios
        .get(API_URL.GENERATOR.FACTORY_SELECT + `?generateFactoryList=${this.selectedFactory.id}`)
        .then((res) => {
          this.generateStoreList = res.data.generateStoreList.map(store => {
            store.generateFactoryId = this.selectedFactory.id;
            return store;
          });
        })
        .catch((err) => {
          console.log(err);
        });
    },
  },

  watch: {
    isShow() {
      if (this.isShow) {
        this.selectedFactory = this.generateFactory[0];
      }
    },
    selectedFactory() {
      this.getGenerateStoreListApi();
    },
    generateFactory() {
      const selectedFactoryId = this.generateFactory.map(f => f.id);
      this.selectedStores = this.selectedStores.filter(store => {
        return selectedFactoryId.includes(store.generateFactoryId); // 排出事業場のリセット
      });
    },
  },
};
</script>
