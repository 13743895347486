<template>
  <div class="c-modal lg" v-if="isShow">
    <div class="c-modal__overlay"></div>
    <div class="c-modal__content">
      <div class="c-modal__head">
        <h5 class="c-lead-s">最終処分場設定</h5>
        <span class="c-modal__head__close" @click="$emit('update:isShow', false)"></span>
      </div>
      <div class="c-modal__body">
        <div class="c-infoList__wrap">
          <div class="c-infoList c-disposalSiteList scroll l-box">
            <div class="c-infoList__row head">
              <div class="c-infoList__cell cb">
                <div class="c-checkbox">
                  <input id="check-all-entrusts" type="checkbox" v-model="isAllChecked" @change="updateAllChecked">
                  <label for="check-all-entrusts" class="c-checkbox__label">
                    <span class="c-checkbox__box"></span>
                  </label>
                </div>
              </div>
              <div class="c-infoList__cell sm">最終処分場名</div>
              <div class="c-infoList__cell stretch">住所</div>
              <div class="c-infoList__cell sm">事業者コード</div>
            </div>
            <template v-for="item in finalDisposalSiteSelectList">
              <div class="c-infoList__row list" :key="'finalDisposalSiteSelectListModal'+item.finalDisposalSiteId">
                <div class="c-infoList__cell cb text-dotted">
                  <div class="c-checkbox">
                    <input :id="'finalDisposalSiteSelectListModalCheckbox-'+item.finalDisposalSiteId" type="checkbox" :value="item.finalDisposalSiteId" v-model="tempList" @change="updateEachChecked">
                    <label :for="'finalDisposalSiteSelectListModalCheckbox-'+item.finalDisposalSiteId" class="c-checkbox__label">
                      <span class="c-checkbox__box"></span>
                    </label>
                  </div>
                </div>
                <div class="c-infoList__cell sm">{{ item.finalDisposalSiteName }}</div>
                <div class="c-infoList__cell stretch">{{ item.addressInfo.prefecturesName }} {{ item.addressInfo.address }} {{ item.addressInfo.buildingName }}</div>
                <div class="c-infoList__cell sm">{{ item.finalDisposalStoreCode }}</div>
              </div>
            </template>
          </div>
        </div>
      </div>
      <div class="c-modal__foot">
        <div class="c-btn primary small" @click="updateStatus">設定</div>
      </div>
    </div>
  </div>
</template>

<script>
import callApi from "../mixins/callApi";

export default {
  props: {
    /**
     * isFinalDisposalSiteSelectListModalShow
     */
    isShow: {
      type: Boolean,
    },
    /**
     * formValue.js
     * form.entrustDisposalCompanyInfo.finalDisposalSiteList
     */
    form: {
      type: Array,
    },
    disposalSiteId: {
      type: String,
    }
  },
  components: {},
  mixins: [callApi],
  data() {
    return {
      /**
       * 全選択チェックボックスの状態
       */
      isAllChecked: false,
      /**
       * 選択した項目の finalDisposalSiteId が格納される
       */
      tempList: [],
    };
  },

  methods: {
    // 202202 CS 委託契約
    /**
     * 個別チェック状態
     */
    updateEachChecked() {
      this.isAllChecked =
        this.finalDisposalSiteSelectList.length === this.finalDisposalSiteSelectList.filter((m) => this.tempList.includes(m.finalDisposalSiteId)).length;
    },
    /**
     * 全選択チェック状態
     */
    updateAllChecked() {
      if(true === this.isAllChecked){
        this.tempList = this.finalDisposalSiteSelectList.map((m) => m.finalDisposalSiteId);
      }else if(false === this.isAllChecked){
        this.tempList = [];
      }
    },
    /**
     * 選択状態をフォームデータと表示に反映
     */
    updateStatus() {
      // 選択項目をform.entrustDisposalCompanyInfo.finalDisposalSiteList に格納。
      this.$emit(
        "update:form",
        this.finalDisposalSiteSelectList.filter((m) => this.tempList.includes(m.finalDisposalSiteId))
      );
      // モーダルを閉じる
      this.$emit("update:isShow", false);
    },
  },

  watch: {
    /**
     * モーダルを開いた時に既存の選択状態に描画を更新
     * モーダル上で表示する情報(最終処分場選択肢取得API)を要求 ※callApi.js
     */
    isShow() {
      if(true === this.isShow){
        this.finalDisposalSiteSelectList = [];
        var self = this;
        // APIを要求する
        this.getFinalDisposalSiteSelectListApi(this.disposalSiteId).then(function() {
          // APIの結果を格納後、選択状態の描画を更新する
          self.tempList = self.form.filter((m) => null !== m.finalDisposalSiteId).map((m) => m.finalDisposalSiteId);
          self.updateEachChecked();
        });
      }
    },
  },
};
</script>

<style scoped lang="scss">
.c-disposalSiteList {
  .stretch {
    flex: 1;
  }

  .c-infoList__row {
    display: flex;
  }

  &__item {
    + .c-disposalSiteList__item {
      border-top: 1px solid #ccd0d7;
      padding-top: 5px;
    }

    .c-inputWrap + & {
      margin-top: 15px;
    }

    display: flex;
    margin-top: 10px;

    span {
      + span {
        margin-left: 1em;
      }
      &.name {
        width: 10em;
      }
      &.address {
        width: 25em;
      }
      &.code {
        width: 12em;
      }
    }
  }
}
</style>
