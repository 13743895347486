import axios from "axios";
import { API_URL } from "@/const";

export default {
  methods: {
    uploadWDSFile(e, index) {
      if (!e.target.files[0]) return;
      const file = e.target.files[0] || e.dataTransfer.files[0];
      const params = new FormData();
      params.append("file", file);
      params.append("fileName", file.name);
      return axios
        .post(API_URL.COMMON.FILE_UPLOAD, params, {
          headers: {
            "Content-Type": "multipart/form-data",
          },
        })
        .then((res) => {
          this.form.entrustWasteList[index].wdsFilePath = res.data.filePath;
          this.form.entrustWasteList[index].wdsFileName =
            res.data.originalFileName;
        })
        .catch((err) => {
          this.errMsgs.push(
            err.response && err.response.data
              ? err.response.data.message
              : err.message
          );
        });
    },

    fileUpload(e) {
      if (!e.target.files[0]) return;
      const file = e.target.files[0] || e.dataTransfer.files[0];
      const params = new FormData();
      params.append("file", file);
      params.append("fileName", file.name);
      return axios.post(API_URL.COMMON.FILE_UPLOAD, params, {
        headers: {
          "Content-Type": "multipart/form-data",
        },
      });
    },

    uploadCollectContractPdf(e, index) {
      return this.fileUpload(e)
        .then((res) => {
          this.form.entrustCollectCompanyInfo[
            index
          ].entrustCollectContractFileList.push({
            contractFilePath: res.data.filePath,
            contractFileName: res.data.originalFileName,
          });
        })
        .catch((err) => {
          this.errMsgs.push(
            err.response && err.response.data
              ? err.response.data.message
              : err.message
          );
        });
    },

    uploadDisposalContractPdf(e) {
      return this.fileUpload(e)
        .then((res) => {
          this.form.entrustDisposalCompanyInfo.entrustDisposalContractFileList.push(
            {
              contractFilePath: res.data.filePath,
              contractFileName: res.data.originalFileName,
            }
          );
        })
        .catch((err) => {
          this.errMsgs.push(
            err.response && err.response.data
              ? err.response.data.message
              : err.message
          );
        });
    },
  },
};
